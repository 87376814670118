import React, { useState,useContext, useEffect, useMemo } from "react";

import {
  formatSource,
  friendlyDealerName,
  friendlyManufacturerName,
  friendlyModelName,
  setColumnVisibility,
} from "../../utils";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid

import "react-datepicker/dist/react-datepicker.css";
import { DataContext } from "../../context/DataContext";
import { AgGridReact } from "ag-grid-react";
import { ListingViewContext } from "../../context/ListingViewContext";
import {useNavigate} from "react-router-dom";
import ColumnChooser from "./ColumnChooser";
import { Cog6ToothIcon } from "@heroicons/react/24/outline";
import { IconButton } from "@material-tailwind/react";

export function AllListingsView({listings}) {

  const navigate = useNavigate();

  const cellClicked = (e) =>{
    navigate(`/dashboard/listing-details/${e.data.uniqueListingId}`)
  }
  const dataContext = useContext(DataContext);

  const dealerCellClicked = (e) =>{
    const dealerFormattedName = e.value;
    const dealerClickedData = dataContext.getDealerData()[dealerFormattedName];
    dataContext.getConstraintState().setSelectedDealer({
      label: dealerFormattedName, 
      value: dealerClickedData
    })
  }

  const userCustomData = dataContext.getCustomUserData();
  const [rowData, setRowData] = useState([]);
  const listingViewContext = useContext(ListingViewContext);
  const cds = useMemo(()=>[...listingViewContext.colDefs], [listingViewContext]);
  cds[0].onCellClicked = cellClicked;
  cds[5].onCellClicked = dealerCellClicked;
  
  const [gridApi, setGridApi] = useState(null);
  const [colDefs] = useState(cds);
    
  useEffect(()=>{
    if (listings && userCustomData){
      const newData = listings.docs.map((l)=>{
        return {
          model: friendlyModelName(dataContext, l.data().model), 
          year: l.data().year, 
          source: formatSource(l.data().source),
          aps: l.data().aps,
          manufacturer: friendlyManufacturerName(dataContext, l.data().manufacturer), 
          price: {price: l.data().price, currency: l.data().currency, price_usd:l.data().price_usd }, 
          total_time: l.data().timeState.totalTime, 
          dealer: friendlyDealerName(dataContext, l.data().dealerName), 
          noPhotos: l.data().np, 
          last_change_dt: l.data().last_change_dt,
          price_change_usd_abs: l.data().price_change_usd_abs,
          price_change_pct_abs_usd: l.data().price_change_pct_abs_usd,
          first_seen: l.data().first_seen,
          last_seen: l.data().last_seen,
          days_active: l.data().days_active,
          reg: l.data().reg,
          ls: l.data().ls,
          noChanges: l.data().no_price_changes,
          uniqueListingId: l.data().uniqueListingId,
          exclude: userCustomData && userCustomData.data() && userCustomData.data().excludeListings && userCustomData.data().excludeListings.includes(l.data().uniqueListingId)
        }
      });

      setRowData(newData);
      setColumnVisibility(gridApi, userCustomData, cds);

      // console.log(`row data set`, newData.length);
    }
  }, [listings, dataContext, userCustomData, gridApi, cds])

  const rowStyle = { background: '' };


  const getRowStyle = params => {
    if (userCustomData && userCustomData.data() && userCustomData.data().excludeListings && userCustomData.data().excludeListings.includes(params.data.uniqueListingId) ) {
        return { background: 'rgb(255 235 238)', opacity: 0.5 };
    }
};

  const autoSizeStrategy = useMemo(() => {
    return {
      type: "fitGridWidth",
      defaultMinWidth: 50,
      columnLimits: [
        {
          colId: "country",
          minWidth: 900,
        },
      ],
    };
  }, []);


  const gridOptions = {
    onGridReady: (params) => {
      setGridApi(params.api);
    }
  };

  const [open, setOpen] = useState(false)

  return (
            <div
              className="ag-theme-quartz h-full" // applying the Data Grid theme
              style={{ height: "80%" }} // the Data Grid will fill the size of the parent container
              >
            <IconButton onClick={()=>setOpen(true)}>
                <Cog6ToothIcon className="h-7 w-7 text-white-500" />
              </IconButton>
              <ColumnChooser open={open} setOpen={setOpen} colDefs={colDefs} userCustomData={userCustomData}></ColumnChooser>
                <AgGridReact
                    rowData={rowData}
                    columnDefs={colDefs}
                    pagination={true}
                    autoSizeStrategy={autoSizeStrategy}
                    rowStyle={rowStyle}
                    getRowStyle={getRowStyle}
                    tooltipShowDelay={0}
                    tooltipHideDelay={10000}
                    gridOptions={gridOptions}
                />
              </div>
  );
}

export default AllListingsView;
