import { useNavigate } from "react-router-dom";

import { UserAuth } from "../../context/AuthContext";

const Profile = () => {
  const navigate = useNavigate();
  const { user, logOut, isAdmin, LMAData } = UserAuth();

  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="max-w-[600px] mx-auto my-16 p-4">
      <h1 className="text-2xl font-bold py-4">Account</h1>
      <p>User Email:{user && user.email}</p>
      <p>Role: { isAdmin ? "Admin" : "User"}</p>
      <p>LMA Data: { LMAData ? "Enabled" : "-"}</p>

      <button onClick={handleLogout} className="border px-6 py-2 my-4">
        Logout
      </button>
    </div>
  );
};

export default Profile;
