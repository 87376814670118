import React, { createContext, useContext, useState, useCallback } from 'react';
import { getDashboardChangesAnalysis } from '../fb-functions';

// Create the MarketReportContext
const MarketReportContext = createContext();

// Custom hook to access context
export const useMarketReportData = () => useContext(MarketReportContext);


function hashCode(obj) {
  const jsonString = JSON.stringify(obj);
  let hash = 0;
  for (let i = 0; i < jsonString.length; i++) {
      const char = jsonString.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash |= 0; // Convert to 32-bit integer
  }
  return hash.toString(16); // Convert to hex for a shorter ID
}

export const MarketReportDataProvider = ({ children }) => {
  const [dataCache, setDataCache] = useState({}); // Cache to store data by parameter
  const [loading, setLoading] = useState(false); // State to track loading status
  const [error, setError] = useState(null); // State to track any errors during fetch

  // Function to fetch data by parameter (e.g., chartId)
  const fetchData = useCallback(
    async (specs) => {

      const specsHash = hashCode(specs);
      console.log(`market data report context FETCH DATA REQ:`, specsHash);

      console.log(`DATA CHACHE: ${dataCache[specsHash]}`);
      // If data for the spec already exists, no need to fetch it again
      if (dataCache[specsHash]) return dataCache[specsHash];

      setLoading(true);
      setError(null);

      try {
        // Fetch data for a specific chartId
        const changesDataResult = await getDashboardChangesAnalysis(specs);
        
        if (changesDataResult.success){

          console.log(`Changes result:`, changesDataResult);

          // Update cache with new data for the specified chartId
          setDataCache(prevCache => ({ ...prevCache, [specsHash]: changesDataResult.data }));
        } else {
          setError(`Failed to fetch data for ${specsHash}`);
        }


        return changesDataResult.data ; // Return fetched data so it can be used immediately if needed
      } catch (err) {
        setError(`Failed to fetch data for chartId ${specsHash}`);
      } finally {
        setLoading(false);
      }
    },
    [dataCache] // Memoize based on dataCache to avoid recreating unnecessarily
  );

  // Memoized context value to provide data, loading, error, and fetch function
  const value = React.useMemo(
    () => ({
      dataCache,
      loading,
      error,
      fetchData, // Expose fetchData for parameterized fetching
    }),
    [dataCache, loading, error, fetchData]
  );

  return <MarketReportContext.Provider value={value}>{children}</MarketReportContext.Provider>;
};