import React, { useState } from "react";
import { UserAuth } from "../../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";

const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const { createUser } = UserAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      console.log("creating user");
      await createUser(email, password);
      navigate("/profile");
    } catch (err) {
      setError(err.message);
      console.log(err);
    }
  };

  return (
    <div className="max-w-[700px] mx-auto my-16 p-4">
      <div>
        <h1 className="text-2xl font-bold py-2">Sign up for an account.</h1>
        <p>
          Already have an account?{" "}
          <Link to="/" className="underline">
            Log in
          </Link>
        </p>
      </div>
      <div>
        <div>Error: {error}</div>
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col py-2">
            <label className="py-2 font-medium">Email:</label>
            <input
              onChange={(e) => setEmail(e.target.value)}
              className="border p-3"
              type="email"
            ></input>
          </div>
          <div className="flex flex-col py-2">
            <label className="py-2 font-medium">Password:</label>
            <input
              onChange={(e) => setPassword(e.target.value)}
              className="border p-3"
              type="password"
            ></input>
          </div>
          <button className="border w-full p-4 bg-blue-600 text-white">
            Sign Up
          </button>
        </form>
      </div>
    </div>
  );
};

export default Signup;
