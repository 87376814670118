import React, { useContext, useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";

import db from "../../firebase";

import {
  where,
  query,
  collectionGroup,
  getCountFromServer,
} from "firebase/firestore";

import { DataContext } from "../../context/DataContext";
import { friendlyTimeFormat } from "../../utils";
import DealerCleaningCellButton from "./DealerCleaningCellButton";

async function getUnformattedDealerCount(){
  const ref = collectionGroup(db, "dealers");
  let q = query(ref, where("formatted", "==", "SERVER_NEW"));
  return await getCountFromServer(q);
}

export default function DealerCleaning() {

  const [dealers] = useState({});
  const [unformattedDealerCount, setUnformattedDealerCount] = useState(0);
  const dataContext = useContext(DataContext);

  useEffect(()=>{
    getUnformattedDealerCount().then((snapshot)=>{
      setUnformattedDealerCount(snapshot.data().count);
    })
  },[dealers])

  const rawDealerData = dataContext.getRawDealerData();

  const [rowData, setRowData] = useState([1]);
  
  useEffect(()=>{
    if (rawDealerData){

      const newData = Object.keys(rawDealerData).sort().map((l)=>{
        const item = rawDealerData[l];
        return {
          dealerName: l, 
          formatted: item.formatted, 
          formattedName: item.formattedName, 
          editedName: l.replaceAll("__FSLASH__","/"), 
          formattedDt: item.formattedDt
        }
      });
      setRowData(newData);
    }
  }, [rawDealerData])



  // Column Definitions: Defines the columns to be displayed.
  const [colDefs] = useState([
    { field: "dealerName", filter: 'agTextColumnFilter', cellDataType: "text"},
    { field: "formatted", filter: 'agTextColumnFilter', cellDataType: "text" },
    { field: "formattedName", filter: 'agTextColumnFilter', cellDataType: "text"},
    { field: "formattedDt",  valueFormatter: params => { return friendlyTimeFormat(params.value); }},
    { field: "editedName", editable: true, },
    { field: "button", headerName: "Save", cellRenderer: DealerCleaningCellButton }
  ]);

  const rowStyle = { background: '' };

  const getRowStyle = params => {
    if (params.data.dealerName === "_____") {
        return { background: 'rgb(255 235 238)', opacity: 0.5 };
    }
  };


  const autoSizeStrategy = useMemo(() => {
    return {
      type: "fitGridWidth",
      defaultMinWidth: 50,
      columnLimits: [
        {
          colId: "country",
          minWidth: 900,
        },
      ],
    };
  }, []);
  

  return (
    
    <div className="mt-12">
      <div>
        Unformatted Dealers: {unformattedDealerCount}
    </div>
            <div className="mt-12 mb-8 flex flex-col gap-12">
            <div
              className="ag-theme-quartz" // applying the Data Grid theme
              style={{ height: 1000 }} // the Data Grid will fill the size of the parent container
              >
                <AgGridReact
                    rowData={rowData}
                    columnDefs={colDefs}
                    pagination={true}
                    autoSizeStrategy={autoSizeStrategy}
                    rowStyle={rowStyle}
                    getRowStyle={getRowStyle}
                />
              </div>
          </div>
     
    </div>
  );
}
