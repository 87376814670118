import React, { useState, useEffect} from 'react';
import {
  collection, orderBy, query, limit,
} from 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';

import {
  Accordion,
  AccordionHeader,
  AccordionBody,
  Typography,
  Button,
} from '@material-tailwind/react';

import './FailedListings.css';

// import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';
import { getFunctions, connectFunctionsEmulator, httpsCallable } from 'firebase/functions';
import db from '../firebase';
import { formatFirebaseTimestamp } from '../utils';

const listingSite = "planecheck_listings";
const manufacturer = "placeholder_all";
const scrapesCollection = "scrapes_v1";

const functions = getFunctions();

const func = httpsCallable(functions, "planecheckListingManualRetry");
if (window.location.href.indexOf('localhost') > -1) {
  connectFunctionsEmulator(functions, '127.0.0.1', 5001);
}

function FailedListings() {
  const options = {
    snapshotListenOptions: { includeMetadataChanges: true },
  };
  
  const [selectedScrapeId, setSelectedScrapeId] = useState('EMPTY');

  const q = query(
    collection(db, `${listingSite}/${manufacturer}/${scrapesCollection}`),
    orderBy('started', 'desc'),
    limit(3),
  );
  const [value, loading, error] = useCollection(q, options);
  const queryString = `planecheck_listings/${manufacturer}/${scrapesCollection}/${selectedScrapeId}/listing_pages_progress_fast`;
  // const queryStringScrapeData = `planecheck_listings/${manufacturer}/${scrapesCollection}/${selectedScrapeId}/listing_scrape_data`;

  const listing_pages_query = query(
    collection(db,queryString ),
    orderBy('started','desc')
   );

  // const listing_scrape_data_query = query(
  //   collection(db,queryStringScrapeData ),
  //   orderBy('started', 'desc'),
  // );

  const [listingPagesValue, listingPagesLoading, listingPagesEerror] = useCollection(listing_pages_query, options);
  // const [listingScrapeDataValue, listingScrapeDataLoading, listingScrapeDataEerror] = useCollection(listing_scrape_data_query, options);
  
  
  useEffect(()=>{
    if(value){
      setSelectedScrapeId(value.docs[0].id);
    }
  }, [value])

  const [open, setOpen] = React.useState(0);

  const handleOpen = (value, docId) => {
    setOpen(open === value ? 0 : value);
    setSelectedScrapeId(docId)
  }

  const getFailures = (data) => {

    const listingAttemptIds = data.listingAttemptIds;
    const successListingsIds = data.successListingsIds;

    const ret = [];
    listingAttemptIds.forEach((l)=>{
      if (!successListingsIds.includes(l)){
        ret.push(l);
      }
    })
    return ret;
  }

  const failedListings = (listingPagesValue, scrapeId) => {
    if (listingPagesValue){
      const ret = listingPagesValue.docs.map((doc, index) => {
        const fails = getFailures(doc.data());
        if (fails.length){
          return fails.map((listingId)=>{
            return (
              <div key={doc.id}>
                <p> {doc.id}</p> 
                <Button onClick={() => handleClickRerun(doc.id, scrapeId, listingId)}>Rerun {listingId}</Button> 
              </div>
            )
          })
        }
        return null;
      })
      return ret;
    } 
  }

  const handleClickRerun = async (page, scrapeId, listingId) =>{

    try {
      const pg = page.replace(`page_`,``);
      console.log(`rerun: ${pg} ${scrapeId} ${listingId}`);
      const res = await func({listingId, page:pg, scrapeId});
      console.log(res);
    } catch(err){
      console.error(err)
    } finally {

    }
  }

  return (
    <div>

      <div className="grid grid-cols-10 grid-rows-1">
        <div className="col-span-9 px-2">
          <Typography>Selected Scrape ID: {selectedScrapeId}</Typography>
        </div>
      </div>    

      <div>
        {error && (
        <strong>
          Error:
          {JSON.stringify(error)}
        </strong>
        )}
        {loading && <span>Collection: Loading...</span>}
        {value
          && value.docs.map((doc, index) => (
            <Accordion open={open === index} key={doc.id}>
              <AccordionHeader onClick={() => handleOpen(index, doc.id)}>
               {doc.id} :: {formatFirebaseTimestamp(doc.data().started)}
              </AccordionHeader>
              <AccordionBody>

                <div>
                  Scrape ID:
                  {doc.id}
                </div>
                <div>
                  No Failed Listings:
                  {doc.data().status }
                </div>
                
                <strong>Listing Page Progress:</strong>
                {listingPagesLoading && <span>Collection: Loading...</span>}
                {listingPagesEerror && <span>ERROR...</span>}
                {failedListings(listingPagesValue, doc.id)}
                
                
              </AccordionBody>
            </Accordion>
          ))}
      </div>
          
    </div>
  );
}

export default FailedListings;
