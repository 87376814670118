import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { UserAuth } from "./AuthContext";
import { collection, orderBy, query } from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import db from "../firebase";
import { getDashboardAnalysis } from "../fb-functions";

const DataContext = createContext()

const options = {
  snapshotListenOptions: { includeMetadataChanges: false},
};

function dataChangedForPanel(panelId, newPanelData, existingData){
  const existingPanelData = existingData[panelId][0];
  const changed = JSON.stringify(newPanelData) !== JSON.stringify(existingPanelData);
  return changed;
}

export const DashboardContextProvider = ({ children }) => {
  
  const [data, setData] = useState({});
  const [panelData, setPanelData] = useState([]);
  const [selectedPanelId, setSelectedPanelId] = useState("")

  // console.log("DataProvider rendered"); // Track provider renders

  const { user } = UserAuth();
  const col = "user_data";
  const userDataPanelsQuery = query(collection(db, `${col}/${user.uid}/dPanels`), orderBy("created"));
  const [snapshot, loading, error] = useCollection(userDataPanelsQuery, options);

  const [loadingDashPanelData, setLoadingDashPanelData] = useState(false);

  // Function to fetch data
  useEffect(() => {
    // console.log("Fetching data");
    
    setLoadingDashPanelData(true);

    const getNewData = async () =>{
      if (snapshot && !loading && user){
        // console.log("FETCHING PANELS DATA:", snapshot.docs.length);
  
        const newData = {...data};
        const newPanelData = [];
        let wasUpdated = false;
  
        for(let i = 0; i<snapshot.docs.length; i++){
  
          const panel = snapshot.docs[i];
          const specs = { ...panel.data(), userId: user.uid, panelId: panel.id};
          newPanelData.push(specs);
          if (!data[panel.id]|| dataChangedForPanel(panel.id, panel.data(), data)){
            const res = await getDashboardAnalysis(specs);
            if (res.success){
              wasUpdated = true;
              newData[panel.id] = [panel.data(), res.data];
            }
          } else {
            // console.log(`No change for panel: ${panel.id}`);
          }
        }
  
        if (wasUpdated){
          // console.log(`newdata`, newData, newPanelData)
          setData(newData);
          setPanelData(newPanelData);
        }
  
      } else{
        // console.log("FETCHING PANELS DATA [NOT READY]");
      }
      setLoadingDashPanelData(false);
    }
    getNewData();
  }, [snapshot, loading, user, data]);

  const value = useMemo(
    () => ({ 
      data, 
      panelData, 
      loading, 
      error,
      selectedPanelId,
      setSelectedPanelId,
      loadingDashPanelData
    }), [data, panelData, loading, error, selectedPanelId, setSelectedPanelId, loadingDashPanelData]);

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};

export const useData = () => {
  return useContext(DataContext);
};

export const useChartData = (panelId) => {
  const { data, loading, error } = useData();
  // Memoize the selected data for the specific panel
  const analysisData = useMemo(() => data[panelId] ? data[panelId][1] : null, [data, panelId]);
  return { analysisData, loading, error };
};

export const useDashboardPanelData = () => {
  const { panelData, loadingDashPanelData } = useData();
  const dashPanelData = useMemo(() => panelData || [] , [panelData]);
  return { dashPanelData, loadingDashPanelData };
};

export const useSelectPanel = () => {
  const { setSelectedPanelId } = useData();
  return setSelectedPanelId;
};

export const useSelectedPanel = () => {
  const { selectedPanelId } = useData();
  return selectedPanelId;
};