import Lister from "./Lister";
export default function PlanecheckListingsAll() {
    return (
        <div>
          
            <div>
                <h1>
                    Planecheck Listings
                </h1>
            </div>
            
            {/* <div>
                <Caller text="Enqueue Listing Summary" functionName="enqueuePlanecheckGetListingPagesCall"></Caller>
            </div>

            <div>
                <Caller text="Test Parse" functionName="testPlanecheckParse"></Caller>
            </div> */}

            <div>
                <Lister configName="planecheck" listingSite="planecheck_listings" default='placeholder_all' scrapesCollection="scrapes_v1" />
            </div>

        </div>
    );
}
