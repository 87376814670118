import {
  Card,
  CardHeader,
  CardBody,
  Typography,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import Select from 'react-select'
import { formatKeyword, formatPrice, friendlyTimeFormat, friendyManufacturerName, friendlyModelName, NO_PRICE_STR } from "../../utils";
import {
  connectFunctionsEmulator,
  getFunctions,
  // httpsCallable,
} from "firebase/functions";
// import { ContextMenu } from "../../components/ContextMenu";
// import { SortMenu } from "./SortMenu";
import { useCallback, useEffect, useRef, useState, useContext } from "react";
import { DataContext } from "../../context/DataContext";
import {
  collection,
  endBefore,
  getCountFromServer,
  limit,
  limitToLast,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import db from "../../firebase";
import { DefaultPagination } from "../../components/Pagination";
import ConstraintSelector from "./ConstraintSelector";

export function BrokerView() {
  const options = {
    snapshotListenOptions: { includeMetadataChanges: true },
  };
  const dataContext = useContext(DataContext);
  // const [sortField, setSortField] = useState("days_active");
  // const [sortDirection, setSortDirection] = useState("desc");
  // const [hideZeroPriceChange, setHideZeroPriceChange] = useState(true);
  const [page, setPage] = useState(1);
  const [noPriceChanges, setNoPriceChanges] = useState({label: "0", value: 0})
  const [noPriceChangesValue, setNoPriceChangesValue] = useState(0)
  // const [seenWithinValue, setSeenWithinValue] = useState({label: "24 hrs", value: "24_HRS"})
  

  // if (hideZeroPriceChange) {
  //   if (sortField === "no_price_changes") {
  //     sortConstraints.push(where("no_price_changes", ">", 0));
  //   // } else if (sortField === "days_since_price_change") {
  //     // days_since_price_change is -1 if there has not been a price change
  //     // means that ordering by days_since_price_change will not display
  //     // listings where the price has not change
  //     // these will be retured when ordering by no_price_chagnes
  //     // sortConstraints.push(where("days_since_price_change", "!=", -1));
  //   } else if (sortField === "last_change_dt") {
  //     sortConstraints.push(where("last_change_dt", "!=", -1));
  //   } else if (sortField === "price_change") {
  //     sortConstraints.push(where("price_change", "!=", NaN));
  //   } else if (sortField === "price") {
  //     sortConstraints.push(where("price", "!=", NaN));
  //   } else if (sortField === "price_change_pct") {
  //     sortConstraints.push(or((where("price_change_pct", "<", 0 )),(where("price_change_pct", ">", 0 ))));
  //     sortConstraints.push(where("price_change_pct", "!=", NaN));
  //   } else if (sortField === "price_range") {
  //     sortConstraints.push(or((where("price_range", "<", 0 )),(where("price_range", ">", 0 ))));
  //     sortConstraints.push(where("price_range", "!=", NaN));
  //   } else if (sortField === "price_change_pct_abs") {
  //     sortConstraints.push(where("price_change_pct_abs", ">", 0));
  //   }
  // }


  // const sourceConstraints = [];
  // const sourcesKeys = Object.keys(sources);
  // for (let i = 0; i < sourcesKeys.length; i++) {
  //   const key = sourcesKeys[i];
  //   if (sources[key]) {
  //     console.log('adding SOURCE filter to include' + key);
  //     sourceConstraints.push(where("source", "==", key));
  //   }
  // }


  const constraints = [];
  // constraints.push(orderBy("last_seen"), orderBy(sortField, sortDirection));

  const selectedModel = dataContext.getConstraintState().selectedModel;
  const selectedManufacturer = dataContext.getConstraintState().selectedManufacturer;
  const selectedCategory = dataContext.getConstraintState().selectedCategory;

  const expandPriceChanges = (noPriceChanges)=>{
    const priceChanges = [0]
    if (noPriceChanges === 1){
      priceChanges.push(1)
    }
    if (noPriceChanges=== 2){
      priceChanges.push(1,2)
    }
    return priceChanges;
  }

  // const lastSeenBeforeTs = () =>{
  //   let days = 1;
  //   if (seenWithinValue.value === "48_HRS") days = 2;
  //   if (seenWithinValue.value === "3_DAYS") days = 3;
  //   if (seenWithinValue.value === "1_WEEK") days = 7;
  //   if (seenWithinValue.value === "1_MONTH") days = 30;
  //   const today = new Date();
  //   const lastSeenBeforeDate = new Date();
  //   lastSeenBeforeDate.setDate(today.getDate() - days);
  //   lastSeenBeforeDate.setUTCHours(0, 0, 0, 0);
  //   const lastSeenBeforeTs = Timestamp.fromDate(lastSeenBeforeDate);
  //   console.log(lastSeenBeforeDate)
  //   return lastSeenBeforeTs;
  // }

  const wheres = [];
  if (noPriceChangesValue !== -1){
    wheres.push(where("no_price_changes", "in", expandPriceChanges(noPriceChangesValue)))
  }
  wheres.push(orderBy("days_active","desc"))

  if (selectedModel.value && selectedModel.value.ids && selectedModel.value.ids ){
    wheres.push(where("model", "in", selectedModel.value.ids))
  }
  if (selectedManufacturer.value && selectedManufacturer.value.ids && selectedManufacturer.value.ids.length > 0){
    wheres.push(where("manufacturer", "in", selectedManufacturer.value.ids))
  }
  if (selectedCategory.value && selectedCategory.value.ids && selectedCategory.value.ids.length > 0){
    wheres.push(where("category", "in", selectedCategory.value.ids))
  }
  
  
  const queryAggregationsDataNoLimit = query(
    collection(db, "listing_aggregations_v1"),
    ...constraints, ...wheres
  );
  
  const listingsPerPage = 25;
  
  const docCursors = useRef()
  
  if (page > 1){
    // console.log('updating constraints', lastDoc.current.id)
    // console.log('direction: ', docCursors.current.direction)
    if (docCursors.current.direction === "forward"){
      // console.log('moving foward')
      constraints.push(startAfter(docCursors.current.docToStartAfter))
      constraints.push(limit(listingsPerPage));
    } else {
      // console.log('moving back')
      constraints.push(endBefore(docCursors.current.docToEndBefore))
      constraints.push(limitToLast(listingsPerPage));
    }
  } else {
    constraints.push(limit(listingsPerPage));
  }

  // console.log("Constraints:", constraints);
  
  const queryAggregationsData = query(
    collection(db, "listing_aggregations_v1"),
    ...wheres, ...constraints
  );

  const [listingsValue] = useCollection(queryAggregationsData, options);
  const [listingsCount, setListingCount] = useState();
  // const [listingStatus, setListingStatus] = useState({});

  useEffect(()=>{
    getCountFromServer(queryAggregationsDataNoLimit)
    .then((snapshot)=>{
      console.log(`got count for query without limit:${snapshot.data().count}`)
      setListingCount(snapshot.data().count);
    })
  }, [queryAggregationsDataNoLimit])

  const handlePageChange = useCallback((nextPage)=>{
    if (listingsValue){
      setPage((page)=>{
        // console.log(`current page is ${page}, next page is ${nextPage}`)

        if (nextPage < page){
          // console.log('setting doc to end before ', listingsValue.docs[0])
          docCursors.current = { direction: "back", docToEndBefore: listingsValue.docs[0]};
        } else {
          // console.log('setting doc to start after ',  listingsValue.docs[listingsValue.docs.length-1])
          docCursors.current = { direction: "forward", docToStartAfter: listingsValue.docs[listingsValue.docs.length-1]};
        }
        // console.log('doc Cursors', docCursors)
        return nextPage;
      })
    }
  }, [listingsValue])

  const functions = getFunctions();
  if (window.location.href.indexOf("localhost") > -1) {
    connectFunctionsEmulator(functions, "127.0.0.1", 5001);
  }

  const handleSetNoPriceChanges = (e) =>{
    console.log(e);
    console.log(`handle set no price changes`)
    setNoPriceChanges(e);
    setNoPriceChangesValue(e.value)
  }

  // const handleSetSeenWithin = (e) =>{
  //   setSeenWithinValue(e);
  // }

  const noPriceChangesOptions = ()=> [
    {label: "0", value: 0},
    {label: "1", value: 1},
    {label: "2", value: 2},
    {label: "ALL", value: -1},
  ];

  // const seenWithinOptions = ()=> [
  //   {label: "24 hrs", value: "24_HRS"},
  //   {label: "48 hrs", value: "48_HRS"},
  //   {label: "3 days", value: "3_DAYS"},
  //   {label: "1 week", value: "1_WEEK"},
  //   {label: "1 month", value: "1_MONTH"},
  // ];

  const handelConstraintsChanged = () =>{
    setPage(1);
  };

  return (
    <>
    
    <div className="flex gap-9">
      
      <ConstraintSelector onChange={()=> handelConstraintsChanged()}/>
      <div className="flex items-center">No. Price Changes</div>
      <Select options={noPriceChangesOptions()} value={noPriceChanges} onChange={(e)=>handleSetNoPriceChanges(e)}></Select>

      {/* <div className="flex items-center">Seen within</div>? */}
      {/* <Select options={seenWithinOptions()} value={seenWithinValue} onChange={(e)=>handleSetSeenWithin(e)}></Select> */}



    </div>
    <div className="mt-12 mb-8 flex flex-col gap-12">
      {listingsValue &&<DefaultPagination page={page} handlePageChange={handlePageChange} listingsCount={listingsCount} listingsPerPage={listingsPerPage}></DefaultPagination>} 
    </div>
    <div className="mt-12 mb-8 flex flex-col gap-12">
      <Card>
        <CardHeader variant="gradient" color="blue" className="mb-8 p-6">
          <div className="flow-root">
            <Typography variant="h6" color="white" className="float-left">
              Price Analysis
            </Typography>
            {/* <SortMenu
              sortField={sortField}
              sortDirection={sortDirection}
              setSortField={setSortField}
              setSortDirection={setSortDirection}
              hideZeroPriceChange={hideZeroPriceChange}
              setHideZeroPriceChange={setHideZeroPriceChange}
              handlePageChange={handlePageChange}
              gridCols={10}
              relWidth={50}
            ></SortMenu> */}
          </div>
        </CardHeader>
        <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
          {/* <div className="w-full min-w-[640px]">
            <Typography variant="h6" color="pink">
              You are looking at page: {page}, total count {listingsCount}
            </Typography>
          </div> */}

          <table className="w-full min-w-[640px] table-auto">
            <thead>
              <tr>
                {[
                  "Title",
                  // "Active",
                  "price",
                  "Δ",
                  "Δ%",
                  // "price change (abs)",
                  "active",
                  "last Δ",
                  "#Δ",
                  // "min price",
                  // "max price",
                  "Manufacturer",
                  "Source",
                  // "First",
                  "last Seen",
                  // "",
                ].map((el) => (
                  <th
                    key={el}
                    className="border-b border-blue-gray-50 py-3 px-5 text-left"
                  >
                    <Typography
                      variant="small"
                      className="text-[11px] font-bold uppercase text-blue-gray-400"
                    >
                      {el}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {listingsValue &&
                listingsValue.docs.map((doc, index) => {
                  const className = `py-3 px-5 ${
                    index === listingsValue.docs.length - 1
                      ? ""
                      : "border-b border-blue-gray-50"
                  }`;

                  return (
                    <tr key={`row${index}`}>
                      <td className={className}>
                        <div className="flex items-center gap-4">
                          {/* <Avatar
                              src={checkedImages[doc.data().uniqueListingId]}
                              alt={index}
                              size="sm"
                            /> */}
                          <Link
                            to={`/dashboard/listing-details/${
                              doc.data().uniqueListingId
                            }`}
                          >
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold"
                            >
                              {doc.data().year} {friendyManufacturerName(dataContext, doc.data().manufacturer)} {friendlyModelName(dataContext, doc.data().model)} 
                            </Typography>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className=""
                            >
                              {doc.data().reg ? doc.data().reg: ""} 
                            </Typography>
                          </Link>
                        </div>
                      </td>
                      {/* <td className={className}>
                        {listingStatus[doc.data().uniqueListingId] &&
                          <Chip
                            variant="gradient"
                            color={
                              listingStatus[doc.data().uniqueListingId]
                                ? "green"
                                : "blue-gray"
                            }
                            value={
                              listingStatus[doc.data().uniqueListingId]
                                ? "active"
                                : "inactive"
                            }
                            className="py-0.5 px-2 text-[10px] font-medium text-center	"
                          />
                        }
                      </td> */}
                      <td className={className}>
                        <Typography
                          variant="small"
                          className="text-xs font-medium text-blue-gray-600"
                        >
                          {formatPrice(doc.data().price, doc.data().currency)}
                        </Typography>
                      </td>

                      {/* <td className={className}>
                        <Typography
                          variant="small"
                          className="text-xs font-medium text-blue-gray-600"
                        >
                          {doc.data().currency}
                        </Typography>
                      </td> */}
                      {/* <td className={className}>
                        <Typography
                          variant="small"
                          className="text-xs font-medium text-blue-gray-600"
                        >
                          { formatPrice(doc.data().price_range,doc.data().currency )}
                        </Typography>
                      </td> */}
                      <td className={className}>
                        <Typography
                          variant="small"
                          color={doc.data().price_change >= 1 ? "green" : "red"}
                          className="text-xs font-medium "
                        >
                          {formatPrice(
                            doc.data().price_change,
                            doc.data().currency
                          )}
                        </Typography>
                      </td>
                      <td className={className}>
                        <Typography
                          variant="small"
                          color={
                            doc.data().price_change_pct * 100 >= 1
                              ? "green"
                              : "red"
                          }
                          className="text-xs font-medium "
                        >
                          {isNaN(doc.data().price_change_pct) ? NO_PRICE_STR : Math.floor(doc.data().price_change_pct * 100) + "%"}
                        </Typography>
                      </td>
                      {/* <td className={className}>
                        <Typography
                          variant="small"
                          className="text-xs font-medium text-blue-gray-600"
                        >
                          {doc.data().days_active
                            ? Math.ceil(doc.data().days_active)
                            : ""}
                        </Typography>
                      </td> */}
                      <td className={className}>
                        <Typography
                          variant="small"
                          className="text-xs font-medium text-blue-gray-600"
                        >
                          {doc.data().days_active
                            ? Math.ceil(doc.data().days_active)
                            : ""}
                        </Typography>
                      </td>                      
                      <td className={className}>
                        <Typography
                          variant="small"
                          className="text-xs font-medium text-blue-gray-600"
                        >
                          {friendlyTimeFormat(doc.data().last_change_dt)}
                        </Typography>
                      </td>
                      {/* <td className={className}>
                        <Typography
                          variant="small"
                          className="text-xs font-medium text-blue-gray-600"
                        >
                          {daysSinceLastPriceChange(doc)}
                        </Typography>
                      </td> */}
                      <td className={className}>
                        <Typography
                          variant="small"
                          className="text-xs font-medium text-blue-gray-600"
                        >
                          {Math.ceil(doc.data().no_price_changes).toString()}
                        </Typography>
                      </td>
                      {/* <td className={className}>
                        <Typography
                          variant="small"
                          className="text-xs font-medium text-blue-gray-600"
                        >
                          { formatPrice(doc.data().min_price,doc.data().currency )}
                        </Typography>
                      </td>
                      <td className={className}>
                        <Typography
                          variant="small"
                          className="text-xs font-medium text-blue-gray-600"
                        >
                          { formatPrice(doc.data().max_price,doc.data().currency )}
                        </Typography>
                      </td> */}
                      {/* <td className={className}>
                        <Typography
                          variant="small"
                          className="text-xs font-medium text-blue-gray-600"
                        >
                          {formatSource(doc.data().source)}
                        </Typography>
                      </td> */}
                      <td className={className}>
                        <Typography
                          variant="small"
                          className="text-xs font-medium text-blue-gray-600"
                        >
                          {friendyManufacturerName(dataContext, doc.data().manufacturer)}
                        </Typography>
                      </td>
                      <td className={className}>
                        <Typography
                          variant="small"
                          className="text-xs font-medium text-blue-gray-600"
                        >
                          {formatKeyword(doc.data().source)}
                        </Typography>
                      </td>
                      {/* <td className={className}>
                        <Typography
                          variant="small"
                          className="text-xs font-medium text-blue-gray-600"
                        >
                          {formatFirebaseTimestamp(doc.data().first_seen)}
                        </Typography>
                      </td> */}
                      <td className={className}>
                        <Typography
                          variant="small"
                          className="text-xs font-medium text-blue-gray-600"
                        >
                          {friendlyTimeFormat(doc.data().last_seen)}
                        </Typography>
                      </td>
                      {/* <td className={className}>
                        <Typography
                          variant="small"
                          className="text-xs font-medium text-blue-gray-600"
                        >
                          {formatFirebaseTimestamp(doc.data().scrapeTs)}
                        </Typography>
                      </td> */}
                      {/* <td className={"visible"}>
                        <ContextMenu menuItems={menuItems} data={doc.data()} />
                      </td> */}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </CardBody>
      </Card>
    </div>
    
    </>
  );
}

export default BrokerView;
