import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, IconButton, Typography } from "@material-tailwind/react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { connectFunctionsEmulator } from "firebase/functions";
import { friendlyTimeFormatDt } from "../../utils";

const functions = getFunctions();

if (window.location.href.indexOf('localhost') > -1) {
    connectFunctionsEmulator(functions, "127.0.0.1", 5001)
}

export default function UserPanel() {

  const [userData, setUserData] = useState([]);
  
  const getUserStatus = ()=>{
    const func = httpsCallable(functions, "getUserStatus");
    func().then((data)=>{
      setUserData(data.data); 
    }).catch((e)=>{
      console.error(e)
    })
  }

  useEffect(() => {
    getUserStatus();
  },[]);

  const handleMLAClaim = async (email, enabled)=>{
    const func = httpsCallable(functions, "setCustomClaims");
    const params = { claimName: "LMAData", email, enabled};
    const res = await func(params);
    console.log(res);
    getUserStatus();
  };

  const handleSetAdmin = async (email, enabled)=>{
    const func = httpsCallable(functions, "setCustomClaims");
    const params = { claimName: "admin", email, enabled};
    const res = await func(params);
    console.log(res);
    getUserStatus();
  };

  const TABLE_HEAD = ["Id", "Email", "Last Active", "Admin", "Has LMA", "Set LMA", "Set Admin"];

  return (
    <div className="mt-12">
      <div className="mt-12 mb-8 flex flex-col gap-12">
        <Card>
          <CardHeader variant="gradient" color="blue" className="mb-8 p-6">
            User data
          </CardHeader>
          <CardBody>
          <table className="w-full min-w-max table-auto text-left">
        <thead>
          <tr>
            {TABLE_HEAD.map((head) => (
              <th
                key={head}
                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
              >
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal leading-none opacity-70"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {userData.map(({ userId, email, lastActive, isAdmin, LMAData }, index) => {
            const isLast = index === userData.length - 1;
            const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";
 
            return (
              <tr key={userId}>
                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {userId}
                  </Typography>
                </td>
                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {email}
                  </Typography>
                </td>
                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {lastActive ? friendlyTimeFormatDt(new Date(lastActive)) : ""}
                  </Typography>
                </td>
                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                  {isAdmin ?    
                    <IconButton variant="outlined">
                      <i className="fas fa-user-tie" />
                    </IconButton> 
                    : 
                    ""}
                  </Typography>
                </td>
                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {LMAData ?    
                    <IconButton color="green" variant="outlined">
                      <i className="fas fa-check" />
                    </IconButton> 
                    : 
                    ""}
                  </Typography>
                </td>
                <td className={classes}>
                  <IconButton onClick={() =>handleMLAClaim(email, true)}>
                    <i className="fas fa-plus" />
                  </IconButton>
                  <IconButton className="ml-2" onClick={() =>handleMLAClaim(email, false)}>
                    <i className="fas fa-minus" />
                  </IconButton>
                </td>
                <td className={classes}>
                  <IconButton onClick={() =>handleSetAdmin(email, true)}>
                    <i className="fas fa-plus" />
                  </IconButton>
                  <IconButton className="ml-2" onClick={() =>handleSetAdmin(email, false)}>
                    <i className="fas fa-minus" />
                  </IconButton>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
