import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Button, IconButton, Input, Tab, TabPanel, Tabs, TabsBody, TabsHeader, Typography } from "@material-tailwind/react";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import CardChart from "../../widgets/cards/card-chart";
import MarketOverview from "./market-overview";
import ListingsView from "./ListingsView";
import { useMarketReportData } from "../../context/MarketReportContext";
import PriceHoursView from "./PriceHoursView";

export function MarketReportView({data, analysisData}) {

  const [title, setTitle] = useState(`Market Report: ${data.name}`);
  const [editing, setEditing] = useState(false);

  const [seriesAvgPx, setSeriesAvgPx] = useState([]);
  const [seriesNoListings, setSeriesNoListings] = useState([]);
  const [seriesDOM, setSeriesDOM] = useState([]);
  const [seriesPR, setSeriesPR] = useState([]);
  const [seriesSales, setSalesData] = useState([])

  const { fetchData, loading, error } = useMarketReportData();

  useEffect(()=>{
    setTitle(data.name);
  }, [data])


  useEffect(() => {
    const getData = async () => {
      const fetchedData = await fetchData(data); // Request data for the specific chartId

      console.log(`mkt report view data`, fetchedData);
      if (!fetchedData)return;
      const dts = fetchedData.results;
   
      const datAvgPx = Object.keys(dts).map((d)=> {return {x: new Date(d).getTime(), y: dts[d].avg}})
      const datNoListings = Object.keys(dts).map((d)=> {return {x: new Date(d).getTime(), y: dts[d].noL}})
      const datDOM = Object.keys(dts).map((d)=> {return {x: new Date(d).getTime(), y: dts[d].dom}})
      const datPR = Object.keys(dts).map((d)=> {return {x: new Date(d).getTime(), y: dts[d].pr * 100}})
      let datSales = Object.keys(dts).map((d)=> { 
        if (dts[d].sl)
          return {x: new Date(d).getTime(), y: dts[d].sl}
        return null;
      })

      datSales = datSales.filter((d)=> d !== null)

      console.log(datSales)

      // const salesDataPoints = fetchedData.avgSalesData.map((p) => {return {x : p.dt_sold, y : p.sale_price_usd }})

      const seriesAvgPx = [
        {
          name: "Avg. Price",
          data: datAvgPx
        },
        {
          name: "Sales Prices",
          data: datSales
        }
      ];

      const seriesNoListings = [
        {
          name: "No. Listings",
          data: datNoListings
        }
      ];

      const seriesNDOMs = [
        {
          name: "DOM",
          data: datDOM
        }
      ];

      const seriesPR  = [
        {
          name: "Average Listing Price Change",
          data: datPR
        }
      ];

      const seriesSalesData  = [
        {
          name: "Sale Price",
          data: datSales
        }
      ];


      console.log(`series avx price`, seriesAvgPx);

      setSeriesAvgPx(seriesAvgPx);
      setSeriesNoListings(seriesNoListings);
      setSeriesDOM(seriesNDOMs);
      setSeriesPR(seriesPR);
      setSalesData(seriesSalesData);
    };

    getData();
  }, [data, fetchData]); // Re-run when chartId changes

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  const forSaleChartData = {
    title: `For sale (${data.period})`,
    series: seriesNoListings
  }

  const listingPriceChartData = {
    title: `Average price (${data.period})`,
    series: seriesAvgPx
  }

  const daysOnMarketChartData = {
    title: `Days on Market (${data.period})`,
    series: seriesDOM
  }

  const priceChangeChartData = {
    title: `Average Listing Price Change % (${data.period})`,
    series: seriesPR
  }

  const salesChartData = {
    title: `Sales (${data.period})`,
    series: seriesSales
  }

  const handleEditing = () => {
    setEditing(true);
  }

  const stopEditing = (e) => {
    if (e.key === "Enter"){
      setEditing(false);
    }
  }

  const tabs = [
    {
      label: "Overview",
      value: "overview",
      content:  <div className="grid gap-y-6 gap-x-6 md:grid-cols-2 xl:grid-cols-2">
                  <MarketOverview data={data} analysisData={analysisData}></MarketOverview>
                  <CardChart data={listingPriceChartData}></CardChart>
                </div>
    },
    {
      label: "Trends",
      value: "trends",
      content:  <div className="grid gap-y-6 gap-x-6 md:grid-cols-2 xl:grid-cols-2">
                  <CardChart data={forSaleChartData}></CardChart>
                  <CardChart data={listingPriceChartData}></CardChart>
                  <CardChart data={daysOnMarketChartData}></CardChart>
                  <CardChart data={priceChangeChartData}></CardChart>
                  <CardChart data={salesChartData}></CardChart>
                </div>
    },
    {
      label: "Listings",
      value: "listings",
      content:  <div className="h-full">
                  <ListingsView className="h-full" dupes={analysisData.ignoredDups} models={data.models} manufacturer={data.manufacturers[0].value.ids} title={data.name} period={data.period} year={data.year} ></ListingsView>
                </div>
    },
    {
      label: "Aircraft Price vs. Total Time",
      value: "price-vs-total-time",
      content:  <div className="h-full">
                  <PriceHoursView data={data}></PriceHoursView>
                </div>
    },
  ];

  return (
    <div className="h-full bg-black-200">
      <div className="bg-blue-300 rounded-md shadow-md">
        <div className="">
          <IconButton
            size="sm"
            variant="text"
            color="white"
            onClick={() => setEditing(true)}>
            <PencilSquareIcon className="w-5 h-5"/>
          </IconButton> 
        </div>
        <div className="flex justify-center pb-5" onDoubleClick={() => handleEditing()}>
            {!editing && 
              <div className="flex flex-row">
                <Typography style={{ userSelect: "none"}} variant="h2" color="white">
                  {title}
                </Typography>
              </div>
            }
            { editing && 
              <div className="flex flex-row w-96 gap-3">
                <Input variant="outlined" onChange={(e) => setTitle(e.target.value)} onKeyDown={(e) => stopEditing(e)} defaultValue={`${title}`}></Input>
                <Button size="sm" variant="small" color="white" onClick={(e) => setEditing(false)}>Save</Button>
              </div>
            }
        </div>
      </div>
        <div className="mt-5 h-full">

        <Tabs value="overview" className="h-full">
          <TabsHeader>
            {tabs.map(({ label, value }) => (
              <Tab key={value} value={value}>
                {label}
              </Tab>
            ))}
          </TabsHeader>
          <TabsBody className="h-full">
            {tabs.map(({ value, content }) => (
              <TabPanel key={value} value={value} className="h-full">
                {content}
              </TabPanel>
            ))}
          </TabsBody>
        </Tabs>


        </div>
    </div>
  );
}

export default MarketReportView;
