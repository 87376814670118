import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Button,
} from "@material-tailwind/react";
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
  // httpsCallable,
} from "firebase/functions";
import {
  collection,
  limit,
  orderBy,
  query,
} from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import db from "../../firebase";
import {formatFirebaseTimestamp} from '../../utils';

export function FXRateView() {
  const options = {
    snapshotListenOptions: { includeMetadataChanges: true },
  };

  const queryData = query(
    collection(db, "fx_rates_daily"),
    orderBy("tsUploaded","desc"), limit(10)
  );
  
  const [listingsValue] = useCollection(queryData, options);
  
  const functions = getFunctions();
  if (window.location.href.indexOf("localhost") > -1) {
    connectFunctionsEmulator(functions, "127.0.0.1", 5001);
  }

  const handleUpdateFX = () =>{
    const func = httpsCallable(functions, "updateFXRates");
    func().then((data)=>{
      console.log("save the rates");
    }).catch((e)=>{
      console.error(e)
    })
  }
  
  return (
    <>
    
    <div className="flex gap-2">

    <Button onClick={()=>handleUpdateFX()}>Update FX</Button>      
        
    </div>

    <div className="mt-12 mb-8 flex flex-col gap-12">
      <Card>
        <CardHeader variant="gradient" color="blue" className="mb-8 p-6">
          <div className="flow-root">
            <Typography variant="h6" color="white" className="float-left">
              FX Rates
            </Typography>
        
          </div>
        </CardHeader>
        <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
          {/* <div className="w-full min-w-[640px]">
            <Typography variant="h6" color="pink">
              You are looking at page: {page}, total count {listingsCount}
            </Typography>
          </div> */}

          <table className="w-full min-w-[640px] table-auto">
            <thead>
              <tr>
                {[
                  "Date",
                  "Uploaded"
                ].map((el) => (
                  <th
                    key={el}
                    className="border-b border-blue-gray-50 py-3 px-5 text-left"
                  >
                    <Typography
                      variant="small"
                      className="text-[11px] font-bold uppercase text-blue-gray-400"
                    >
                      {el}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {listingsValue &&
                listingsValue.docs.map((doc, index) => {
                  const className = `py-3 px-5 ${
                    index === listingsValue.docs.length - 1
                      ? ""
                      : "border-b border-blue-gray-50"
                  }`;

                  return (
                    <tr key={`row${index}`}>
                      
                      <td className={className}>
                        <Typography
                          variant="small"
                          className="text-xs font-medium text-blue-gray-600"
                        >
                          {doc.id}
                        </Typography>
                      </td>

                      <td className={className}>
                        <Typography
                          variant="small"
                          className="text-xs font-medium text-blue-gray-600"
                        >
                          { formatFirebaseTimestamp(doc.data().tsUploaded)}
                        </Typography>
                      </td>


                    </tr>
                  );
                })}
            </tbody>
          </table>
        </CardBody>
      </Card>
    </div>
    
    </>
  );
}

export default FXRateView
