import { Button } from '@material-tailwind/react';
import { doc, getDoc, increment, serverTimestamp, updateDoc } from 'firebase/firestore';
import React from 'react';

import db from "../../firebase";

const DealerCleaningCellButton = (params) => {

  const saveClick = async (e) => {

    let rowData = [];
    params.api.forEachNode(node => rowData.push(node.data));
    
    console.log(e);
    console.log(params);
    console.log(`edited name`, params.data.editedName);

    const docRef = doc(db, "dealers", params.data.dealerName);

    await updateDoc(docRef, {
      formattedName: params.data.editedName,
      formatted: increment(1),
      formattedDt: serverTimestamp()
    });

    // e.data.formattedName = editedValue;
    console.log("Document written with ID: ", docRef.id);

    const newDoc = await getDoc(docRef);
    
    const update = {
      dealerName: newDoc.id,
      formatted: newDoc.data().formatted,
      formattedDt: newDoc.data().formattedDt,
      formattedName: newDoc.data().formattedName
    }

    params.api.forEachNode((node) => {
      if (node.data.dealerName  === params.data.dealerName){
        node.setData(update);
      }
    });
  }

      return (
        <Button onClick={(e)=>saveClick(e)} size='sm'>Save</Button>
      );
  };
  
  export default DealerCleaningCellButton;
  

