import { Button, Card, CardBody, Checkbox, Dialog, DialogHeader, List, ListItem, ListItemPrefix, Typography } from "@material-tailwind/react";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import { arrayRemove, arrayUnion, doc, setDoc } from "firebase/firestore";
import "react-datepicker/dist/react-datepicker.css";
import db from "../../firebase";
import { UserAuth } from "../../context/AuthContext";

export function ColumnChooser({ colDefs, open, setOpen, userCustomData }) {

  const handleOpen = () => {}
  const { user } = UserAuth();

  const fieldChecked = async (e, field) =>{
    const docRef = doc(db, "user_data", user.uid, );
    const updateObj = {
      "listingViewColSettings": !e.target.checked ? arrayUnion(field) : arrayRemove(field)
    }
    await setDoc(docRef, updateObj, {merge:true});
  }

  if (!userCustomData){
    return <div>Loading</div>
  }

  const isDefaultChecked = (field) =>{
    if (userCustomData && userCustomData.data().listingViewColSettings 
          && !userCustomData.data().listingViewColSettings.includes(field)){
            return true
          }
    else {
      if (!userCustomData || !userCustomData.data().listingViewColSettings){
        return true;
      }
    }
    
  }

  return (
    <Dialog
    size="xs"
    open={open}
    handler={handleOpen}
    className="bg-transparent shadow-none"
  >
    <DialogHeader className="flex flex-row-reverse">
          <Button
            variant="text"
            color="red"
            onClick={() => setOpen(false)}
            className="mr-1">
            <span>Close</span>
          </Button>
        </DialogHeader>
    <Card className="mx-auto w-full max-w-[24rem]">
      
      <CardBody className="flex flex-col gap-4">

      <List>
      {colDefs && colDefs.map((c, index)=>
            
          <ListItem className="p-0" key={`list-item-${index}`}>
            <label
              htmlFor="vertical-list-react"
              className="flex w-full cursor-pointer items-center px-3 py-2"
            >
              <ListItemPrefix className="mr-3">
                <Checkbox
                  id={`vertical-list-${index}`}
                  ripple={false}
                  className="hover:before:opacity-0"
                  containerProps={{
                    className: "p-0",
                  }}
                  defaultChecked={isDefaultChecked(c.field)}
                  onClick={(e) => fieldChecked(e, c.field)}
                />
              </ListItemPrefix>
              <Typography color="blue-gray" className="font-medium">
              {c.headerName ? c.headerName: `${c.field} **`}
              </Typography>
            </label>
        </ListItem>
      )}
      </List>
       </CardBody>
    </Card>
  </Dialog>
    
  );
}

export default ColumnChooser;
