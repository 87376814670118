import {
  Card,
  CardHeader,
  CardBody,
  Typography,
} from "@material-tailwind/react";

import Chart from "react-apexcharts";
import PropTypes from "prop-types";

const chartOptions = {
  chart: {
    toolbar: {
      show: true,
    },
  },
  markers: {
    size: [6, 0],
  },
  title: {
    show: "Default Chart Title",
  },
  // tooltip: {
  //   custom: function({ series, seriesIndex, dataPointIndex, w }) {
  //     // Only return the value and omit the series name
  //     return '<div class="tooltip-custom" style="padding: 3px">' +
  //              '<span><b>' +  priceHoursData[dataPointIndex].r  + `</b> $ ${Math.round(series[seriesIndex][dataPointIndex])} k </span>` +
  //            '</div>';
  //   },
  //   enabled: true,
  //   x: {
  //     show: true
  //   },
  //   y: {
  //     formatter: function (val, { dataPointIndex, seriesIndex}) {
  //       if (seriesIndex === 0){
  //         return priceHoursData[dataPointIndex].r + ` $${val} k` // Custom formatting of the Y-axis value
  //       } else {
  //         return null;
  //       }
  //     }
  //   },
  // },
  dataLabels: {
    enabled: false,
  },
  colors: ["#d40f04", "#40a832"],
  legend: {
    show: false,
  },
    xaxis: {
      type: "datetime",
      labels: {
        format: "dd MMM yy",
      },
      tickAmount: 5
    },
  yaxis: {
    type: 'numeric',
    tickAmount: 10,
    labels: {
      formatter: function (value) {
        return value.toFixed(0); // Rounds the value to 2 decimal places
      }
    }
  }
};

export function CardChart({ data, height }) {
  

  return (
      <Card className="mt-10">
         <CardHeader
        variant="gradient"
        color={"blue"}
        className="mb-2 p-2 grid place-items-center"
      >
        <Typography variant="h5" color="white">
          {data.title}
        </Typography>
      </CardHeader>
        <CardBody className="">
        <Chart options={chartOptions} series={data.series} type="line" height={250} />
        </CardBody>
          {/* <CardFooter className="flex gap-10">
            <Button className="" onClick={(e)=>handleClickChangesAnalysis(data)}>Changes Analysis</Button> 
          </CardFooter> */}
      </Card>
  );
}

CardChart.defaultProps = {
  color: "blue",
  footer: null,
};

CardChart.propTypes = {
  color: PropTypes.oneOf([
    "white",
    "blue-gray",
    "gray",
    "brown",
    "deep-orange",
    "orange",
    "amber",
    "yellow",
    "lime",
    "light-green",
    "green",
    "teal",
    "cyan",
    "light-blue",
    "blue",
    "indigo",
    "deep-purple",
    "purple",
    "pink",
    "red",
  ]),
  footer: PropTypes.node,
};

CardChart.displayName = "/src/widgets/cards/card-chart.jsx";

export default CardChart;
