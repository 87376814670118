import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Input,
  CardHeader,
  Button,
  Typography,
} from "@material-tailwind/react";

import db from "../../firebase";

import {
  collectionGroup,
  doc,
  increment,
  getDocs,
  query,
  where,
  updateDoc,
} from "firebase/firestore";

export default function CategoriesCleaning({hideFormatted}) {
  const [categories, setCategories] = useState({});
  const [dataUpdated, setDataUpdated] = useState({});
  

  useEffect(() => {
    async function getCategories(hideFormatted) {
      const categoriesRef = collectionGroup(db, "category_data");
      
      const constraints = [];
      if (hideFormatted){
        constraints.push(where("formatted", "==", "SERVER_NEW"));
      }
      const scrapes = query(categoriesRef, ...constraints);
      const s = await getDocs(scrapes);
      return s;
    }

    const newCategories = {};

    getCategories(hideFormatted).then((snapshot) => {
      for (let i = 0; i < snapshot.docs.length; i++) {
        const doc = snapshot.docs[i];
        const id = doc.id;
        newCategories[id] = doc.data();
        newCategories[id].editedName = doc.data().formattedName ? doc.data().formattedName : id;
      }
      setCategories(newCategories);
    });
  }, [dataUpdated, hideFormatted]);

  const handleUpdate = async (key, source, e) => {
    // console.log(categories[key].editedName);

    try {
      if (!categories[key].editedName){
        return;
      }
      const docRef = doc(db, "categories", source, "category_data", key);

      await updateDoc(docRef, {
        formattedName: categories[key].editedName,
        formatted: increment(1)
      });
      setDataUpdated({});
      console.log("Document written with ID: ", docRef.id);
    } catch (e) {
      console.error("Error adding document: ", e);
    }

    const newData = { ...categories };
    setCategories(newData);
  };

  const handleChange = (key, e) => {
    console.log(e.target.value);
    const newData = { ...categories };
    newData[key].editedName = e.target.value;
    setCategories(newData);
    console.log(categories);
  };

  const rows = Object.keys(categories).sort().map((key, index) => {
    const className = `py-3 px-5 ${
      index === Object.keys(categories).length - 1
        ? ""
        : "border-b border-blue-gray-50"
    }`;

    return (
      <tr key={key}>
        <td className={className}>
          <Typography
            className="text-xs font-medium text-blue-gray-600"
          >
            {key}
          </Typography>
        </td>
        <td className={className}>
          <Typography
            className="text-xs font-medium text-blue-gray-600"
          >
            {categories[key].formattedName ? categories[key].formattedName : "" }
          </Typography>
        </td>
        <td className={className}>
          <Typography
            className="text-xs font-medium text-blue-gray-600"
          >
            {categories[key].source}
          </Typography>
        </td>
        <td className={className}>
          <Input
            className="text-xs font-medium text-blue-gray-600"
            onChange={(e) => handleChange(key, e)} defaultValue={categories[key].editedName} 
          ></Input>
        </td>
        <td className={className}>
          <Button onClick={(e) => handleUpdate(key,categories[key].source, e)}>Update</Button>
        </td>
      </tr>
    );
  });

  return (
    <div className="mt-12">
      <div className="mt-12 mb-8 flex flex-col gap-12">
        <Card>
          <CardHeader variant="gradient" color="blue" className="mb-8 p-6">
            Category Name Mapping (identically named manufacturers show once)
          </CardHeader>
          <CardBody>
            <table className="w-full min-w-max table-auto text-left">
              <thead>
                <tr>
                  {["Unformatted", "Formatted Name", "Source", "Edited Name", ""].map(
                    (el) => (
                      <th
                        key={el}
                        className="border-b border-blue-gray-50 py-3 px-5 text-left"
                      >
                        <Typography
                          className="text-[11px] font-bold uppercase text-blue-gray-400"
                        >
                          {el}
                        </Typography>
                      </th>
                    )
                  )}
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </table>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
