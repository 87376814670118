import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Button,
  Input,
} from "@material-tailwind/react";
import { and, collectionGroup, getCountFromServer, query, where } from "firebase/firestore";
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions";
import { useEffect, useState } from "react";
import db from "../../firebase";

export function ReprocessingView() {

  const functions = getFunctions();
  console.log('me ' + window.location.href);

  if (window.location.href.indexOf('localhost') > -1) {
      connectFunctionsEmulator(functions, "127.0.0.1", 5001)
  }

  const [id, setID] = useState("");
  const [priceIssue2201Count, setPriceIssue2201Count] = useState("");
  const [response, setResponse] = useState({msg: "", error: false});
  const functionName = "recalcListingAggregation";
  const reprocess = httpsCallable(functions, functionName);

  const handleReprocess = async (uniqueListingId) => {
    setResponse({msg:"",error:false});
    const response = await reprocess({uniqueListingId});
    setResponse(response.data);
  };
  
  const handleChange = (e) =>{
    setID(e.target.value);
  }

  const queryPriceIssueCount = query(
    collectionGroup(db, "listing_scrapes"),
    and(where("price", ">", 50000000),where("source","==","planecheck"))
  );

  useEffect(()=>{
    getCountFromServer(queryPriceIssueCount)
    .then((snapshot)=>{
      console.log(`got cleaned count:${snapshot.data().count}`)
      setPriceIssue2201Count(snapshot.data().count);
    })
  }, [queryPriceIssueCount])


  return (
    <div className="mt-12 grid grid-cols-4 gap-4">
      <Card>
        <CardHeader variant="gradient" color="blue" className="mb-8 p-6">
            <Typography className="justify-center" variant="h6" color="white" >
              Re-proceesing
            </Typography>
        </CardHeader>
        <CardBody className="items-center">

          <div className="flex flex-col gap-4">
            <Input onChange={e => handleChange(e)}></Input>
            <Button onClick={()=>handleReprocess(id)}>Re-process</Button>
            {/* <Typography>
              ID: {id}
            </Typography> */}
            <Typography color={response.error ? "red": "green"}>
              {response.msg}
            </Typography>
          </div>

        </CardBody>
      </Card>
      <Card>
        <CardHeader variant="gradient" color="blue" className="mb-8 p-6">
            <Typography className="justify-center" variant="h6" color="white" >
              2201 Bad Price Issue
            </Typography>
        </CardHeader>
        <CardBody className="items-center">

          <div className="flex flex-col gap-4">
         
            <Typography color={"red"}>
              Affected: {priceIssue2201Count}
            </Typography>
          </div>

        </CardBody>
      </Card>
    </div>
   
  );
}

export default ReprocessingView;
