import Plot from "react-plotly.js";
import { addHours } from "../utils";

export function ListingDetailsChart({ timeseries, priceChange }) {
  const x = timeseries.map((el) => el.dt);
  const y = timeseries.map((el) => el.price);
  const col = priceChange > 0 ? "green" : "red";

  let priceForManualRange = NaN;

  if (Math.abs(priceChange) === 0 || isNaN(priceChange)){
    for(let i =0;i<timeseries.length;i++){
      if (!isNaN(timeseries[i].price)){
        priceForManualRange = timeseries[i].price;
        break;
      }
    }
  }

  const markerSize = timeseries.length > 1 ? 2 : 7;

  const manualRangeMin = priceForManualRange * 0.9;
  const manualRangeMax = priceForManualRange * 1.1;
  const manualRange = [manualRangeMin, manualRangeMax];

  // const allNaN = timeseries.filter(item => isNaN(item.price)).length === timeseries.length;

  const layout = {
    autosize: true,
    title: "Price History ($)",
    xaxis: {
      autotick: true,
      automargin: true,
    },
    yaxis: {
      automargin: true,
    },
  };

  if (Math.abs(priceChange) > 0){
    layout.yaxis['autorange'] = true;
    // console.log('autorange')
  } else {
    layout.yaxis['range'] = manualRange;
    // console.log('manual range', manualRange)
  }

  if (timeseries.length === 1){
    layout.xaxis['tickformat'] = '%H:%M:%S '
    layout.xaxis['range'] = [addHours(timeseries[0].dt,-0.5),addHours(timeseries[0].dt,0.5)];
  }

  // if (allNaN) return (<div/>)

  return (
    
    <Plot
    style={{width: "100%", height: "100%"}}

      data={[
        {
          type: "scatter",
          x: x,
          y: y,
          mode: "lines+markers",
          marker: {
            size: markerSize
          },
          name: "series1",
          line: { color: col },
        },
      ]}
      layout={layout}
    />
  );
}
