import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Dialog,
  Input,
} from "@material-tailwind/react";
import PropTypes from "prop-types";
import React, { useState } from "react";
import DashboardConstraintSelector from "../../pages/dashboard/DashboardConstraintSelector";

export function DashboardEditDialog({open, handleOpen, handleSave, handleQuit, data, mode}) {

  const defaultName = (mode === "add" ? "" : data.name) 
  const [name, setName] = useState(defaultName);
  
  const defaultManufacturer = mode === "add" ? {label: "All Manufacturers", value: "All Manufacturers"} : data.manufacturers[0];
  const defaultModel = mode === "add" ?  [{label: "All Models", value: "All Models"}] : data.models;
  const defaultPeriod = mode === "add" ?  "3m" : data.period;
  const defaultYear = mode === "add" ?  [{label: "All Years", value: "All Years"}] : data.year;
  
  const [selectedManufacturer, setSelectedManufacturer] = useState(defaultManufacturer);
  const [selectedModel, setSelectedModel] = useState(defaultModel);
  const [selectedPeriod, setSelectedPeriod] = useState(defaultPeriod);
  const [selectedYear, setSelectedYear] = useState(defaultYear);

  const updateName = (e) =>{
    setName(e.target.value);
  }

  const handleCloseInternal = () => {
    if (mode === "add") clear();
    handleQuit();
  }

  const handleSaveInternal = (name, selectedManufacturer, selectedModel, period, year) => {
    handleSave(name, selectedManufacturer, selectedModel, period, year)
    if (mode === "add") clear();
  }

  const clear = () => {
    setName('');
    setSelectedManufacturer({label: "All Manufacturers", value: "All Manufacturers"});
    setSelectedModel({label: "All Models", value: "All Models"});
    setSelectedPeriod("3m")
  }

  return (
      
      <Dialog
        size="md"
        open={open}
        handler={handleOpen}
        outsidePress={()=>handleCloseInternal()}
        escapeKey={()=>handleCloseInternal()}
        className="bg-transparent shadow-none backdrop:none"
      >
        <Card className="mx-auto w-full">
          <CardBody className="flex flex-col gap-4">
           
            <Input label="Name" size="lg" value={name} onChange={(e)=>updateName(e)}/>

            <DashboardConstraintSelector 
              selectedManufacturer={selectedManufacturer}
              selectedModel={selectedModel}
              selectedPeriod={selectedPeriod}
              selectedYear={selectedYear}
              setSelectedManufacturer={setSelectedManufacturer}
              setSelectedModel={setSelectedModel}
              setSelectedPeriod={setSelectedPeriod}
              setSelectedYear={setSelectedYear}
            ></DashboardConstraintSelector>

          </CardBody>
          <CardFooter className="pt-5">
            <div className="flex flex-row items-center4 ">
            <Button 
              className="border p-1 bg-blue-300 text-white basis-1/2" 
              variant="gradient" 
              onClick={(e)=> handleSaveInternal(name, selectedManufacturer, selectedModel, selectedPeriod, selectedYear)}>Save</Button>
            <Button
              className="basis-1/2"
              variant="text"
              color="red"
              onClick={()=>handleCloseInternal()}>
              <span>Cancel</span>
            </Button>
            </div>
          </CardFooter>
        </Card>
      </Dialog>


  );
}

DashboardEditDialog.defaultProps = {
  color: "blue",
  footer: null,
};

DashboardEditDialog.propTypes = {
  color: PropTypes.oneOf([
    "white",
    "blue-gray",
    "gray",
    "brown",
    "deep-orange",
    "orange",
    "amber",
    "yellow",
    "lime",
    "light-green",
    "green",
    "teal",
    "cyan",
    "light-blue",
    "blue",
    "indigo",
    "deep-purple",
    "purple",
    "pink",
    "red",
  ]),
  icon: PropTypes.node,
  title: PropTypes.node,
  value: PropTypes.node,
  footer: PropTypes.node,
};

DashboardEditDialog.displayName = "/src/widgets/cards/dashboard-edit-dialog.jsx";

export default DashboardEditDialog;
