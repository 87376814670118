import {
  Card,
  CardBody,
  Typography,
  Dialog,
  CardFooter,
  Input,
  Spinner,
} from "@material-tailwind/react";

import {
  and,
  collection,
  orderBy,
  doc,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import React, { useState, useContext } from "react";

import db from "../../firebase";
import {
  connectFunctionsEmulator,
  getFunctions,
} from "firebase/functions";
import {
  periodToDate
} from "../../utils";
import {
  useCollection,
} from "react-firebase-hooks/firestore";

import "react-datepicker/dist/react-datepicker.css";
import ConstraintSelector from "./ConstraintSelector";
import { DataContext } from "../../context/DataContext";

import { Button } from "react-bootstrap";
import AllListingsView from "./AllListingsView";
import PeriodSelector from "./PeriodSelector";

const functions = getFunctions();

if (window.location.href.indexOf("localhost") > -1) {
  connectFunctionsEmulator(functions, "127.0.0.1", 5001);
}

export function AllListings() {

  const dataContext = useContext(DataContext);
  const [sources] = useState({});
  const [open, setOpen] = React.useState(false);
  const [selectedEdit, setSelectedEdit] = useState(null);

  const handleOpen = (data) => {
    setOpen(true);
    setSelectedEdit(data);
  };

  const [editYear, setEditYear] = useState("-");
  const [editModel, setEditModel] = useState("-");
  const [editCategory, setEditCategory] = useState("-");
  
  const handleQuit = () =>{
    clear();
  }

  const handleClose = async () => {
    console.log(`closing with: `, editYear, editModel, editCategory);
    if (!selectedEdit){
      return ;
    }
    try {
      const uniqueListingId = selectedEdit.uniqueListingId;
      console.log(`update for ${uniqueListingId}`)
      const docRef = doc(db, "listing_aggregations_v1", uniqueListingId);

      await updateDoc(docRef, {
        year: editYear.trim(),
        model: editModel.trim(),
        category: editCategory.trim()
      });

      console.log("Document written with ID: ", docRef.id);

    } catch (e) {
      console.error("Error adding document: ", e);
    }
    clear();

  }

  const clear = ()=>{
    setSelectedEdit(null);
    setEditYear("-");
    setEditModel("-");
    setEditCategory("-");
    setOpen(false);
  }

  const updateYear = (e) =>{
    setEditYear(e.target.value);
  }

  const updateModel = (e) =>{
    setEditModel(e.target.value);
  }

  const updateCategory = (e) =>{
    setEditCategory(e.target.value);
  }

  const options = {
    snapshotListenOptions: { includeMetadataChanges: true },
  };
  
  let sourceConstraints = [];
  Object.keys(sources).forEach((s)=>{
    if (sources[s].selected){
      sourceConstraints = sourceConstraints.concat(s);
    }
  })

  const COLLECTION = "listing_aggregations_v1";

  const selectedModel = dataContext.getConstraintState().selectedModel;
  const selectedManufacturer = dataContext.getConstraintState().selectedManufacturer;
  const selectedCategory = dataContext.getConstraintState().selectedCategory;
  const selectedDealer = dataContext.getConstraintState().selectedDealer;
  const selectedPeriod = dataContext.getConstraintState().selectedPeriod;
  const setSelectedPeriod = dataContext.getConstraintState().setSelectedPeriod;


  const wheres = [];
  if (selectedModel.value && selectedModel.value.ids && selectedModel.value.ids ){
    wheres.push(where("model", "in", selectedModel.value.ids))
  }
  if (selectedManufacturer.value && selectedManufacturer.value.ids && selectedManufacturer.value.ids.length > 0){
    wheres.push(where("manufacturer", "in", selectedManufacturer.value.ids))
  }
  if (selectedCategory.value && selectedCategory.value.ids && selectedCategory.value.ids.length > 0){
    wheres.push(where("category", "in", selectedCategory.value.ids))
  }
  if (selectedDealer.value && selectedDealer.value.ids && selectedDealer.value.ids.length > 0){
    wheres.push(where("dealerName", "in", selectedDealer.value.ids))
  }
  if (selectedPeriod){
    wheres.push(where("last_seen", ">=", periodToDate(selectedPeriod) ))
  }


  let queryNewListingsData = null;
  
  if (selectedManufacturer.label !== "All Manufacturers" || selectedDealer.label !== "All Dealers") {
    queryNewListingsData = query(
      collection(db, COLLECTION),
      and(...wheres),
      orderBy("last_seen", "desc")
    );
  }

  const [listingsValue, loading, error] = useCollection(queryNewListingsData, options);


  return (
    <div className="h-full">
      
      <Dialog
        size="xs"
        open={open}
        handler={handleOpen}
        outsidePress={()=>handleQuit}
        escapeKey={()=>handleQuit}
        className="bg-transparent shadow-none backdrop:none"
      >
        <Card className="mx-auto w-full max-w-[24rem]">
          <CardBody className="flex flex-col gap-4">
            
          <Typography variant="h4" color="blue-gray">
              {selectedEdit ? selectedEdit.reg : ""}
            </Typography>
            <Typography variant="h4" color="blue-gray">
              {selectedEdit ? selectedEdit.title : ""}
            </Typography>

            <Typography variant="h4" color="blue-gray">
              {selectedEdit ? selectedEdit.source : ""}
            </Typography>

            <Typography className="-mb-2" variant="h6">
              Year
            </Typography>
            [Current: {selectedEdit ? selectedEdit.year : ""}]
            <Input label="Year" size="lg" value={editYear} onChange={(e)=>updateYear(e)}/>
            <Typography className="-mb-2" variant="h6">
              Model
            </Typography>
            [Current: {selectedEdit ? selectedEdit.model : ""}]
            <Input label="Model" size="lg" value={editModel} onChange={(e) => updateModel(e)}/>
            <Typography className="-mb-2" variant="h6">
              Category
            </Typography>
            [Current: {selectedEdit ? selectedEdit.category : ""}]
            <Input label="Model" size="lg" value={editCategory} onChange={(e) => updateCategory(e)}/>
          </CardBody>
          <CardFooter className="pt-0">
            <Button className="border p-1 bg-blue-300 text-white" variant="gradient" onClick={(e)=> handleClose()} fullWidth>
              Save
            </Button>
            <Button
            variant="text"
            color="red"
            onClick={()=>handleQuit()}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          </CardFooter>
        </Card>
      </Dialog>

      <div className="flex gap-5">
          <ConstraintSelector />
              <div>
                <PeriodSelector selectedPeriod={selectedPeriod} setSelectedPeriod={setSelectedPeriod}></PeriodSelector>
              </div>
      </div>

      <div className="mt-12 mb-8 flex flex-col gap-12 h-full">

          {error && <p>ERROR!{JSON.stringify(error)}</p>}
          {loading && <> 
            <Spinner></Spinner>
          <Typography className="-mb-2" variant="h6">
              Loading...
            </Typography></>}
          {listingsValue && <AllListingsView listings={listingsValue}></AllListingsView>}
      </div>

    </div>
  );
}

export default AllListings;
