import {
  Card,
  CardHeader,
  CardBody,
  Typography,
} from "@material-tailwind/react";
import {
  connectFunctionsEmulator,
  getFunctions,
  // httpsCallable,
} from "firebase/functions";
// import { ContextMenu } from "../../components/ContextMenu";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  collection,
  endBefore,
  getCountFromServer,
  limit,
  limitToLast,
  query,
  startAfter,
} from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import db from "../../firebase";
import { DefaultPagination } from "../../components/Pagination";
import ConstraintSelector from "./ConstraintSelector";

export function DealerView() {
  const options = {
    snapshotListenOptions: { includeMetadataChanges: true },
  };


  const [page, setPage] = useState(1);
  

  const constraints = [];
    const wheres = [];
  
  const queryAggregationsDataNoLimit = query(
    collection(db, "dealer_listings"),
    ...constraints, ...wheres
  );
  
  const listingsPerPage = 25;
  
  const docCursors = useRef()
  
  if (page > 1){
    // console.log('updating constraints', lastDoc.current.id)
    // console.log('direction: ', docCursors.current.direction)
    if (docCursors.current.direction === "forward"){
      // console.log('moving foward')
      constraints.push(startAfter(docCursors.current.docToStartAfter))
      constraints.push(limit(listingsPerPage));
    } else {
      // console.log('moving back')
      constraints.push(endBefore(docCursors.current.docToEndBefore))
      constraints.push(limitToLast(listingsPerPage));
    }
  } else {
    constraints.push(limit(listingsPerPage));
  }
  
  const queryAggregationsData = query(
    collection(db, "dealer_listings"),
    ...constraints, ...wheres
  );


  const [listingsValue] = useCollection(queryAggregationsData, options);
  const [listingsCount, setListingCount] = useState();
  // const [listingStatus, setListingStatus] = useState({});

  useEffect(()=>{
    getCountFromServer(queryAggregationsDataNoLimit)
    .then((snapshot)=>{
      console.log(`got count for query without limit:${snapshot.data().count}`)
      setListingCount(snapshot.data().count);
    })
  }, [queryAggregationsDataNoLimit])

  const handlePageChange = useCallback((nextPage)=>{
    if (listingsValue){
      setPage((page)=>{
        // console.log(`current page is ${page}, next page is ${nextPage}`)

        if (nextPage < page){
          // console.log('setting doc to end before ', listingsValue.docs[0])
          docCursors.current = { direction: "back", docToEndBefore: listingsValue.docs[0]};
        } else {
          // console.log('setting doc to start after ',  listingsValue.docs[listingsValue.docs.length-1])
          docCursors.current = { direction: "forward", docToStartAfter: listingsValue.docs[listingsValue.docs.length-1]};
        }
        // console.log('doc Cursors', docCursors)
        return nextPage;
      })
    }
  }, [listingsValue])


  const functions = getFunctions();
  if (window.location.href.indexOf("localhost") > -1) {
    connectFunctionsEmulator(functions, "127.0.0.1", 5001);
  }

  const dealerName = (doc)=>{
    if (!doc) return "-"
    return doc.id.replaceAll("_FSLASH_","/");
  }

  const dealerIndividuals = (doc) =>{
    if (!doc)return "-";
    const individuals = doc.data().individualName.join(", ");
    return individuals;
  }


  return (
    <>
    
    {/* <SourceSelector setSources={setSources} sources={sources}/> */}
    <div className="flex gap-2">
          <ConstraintSelector/>
          
      {listingsValue &&<DefaultPagination page={page} handlePageChange={handlePageChange} listingsCount={listingsCount} listingsPerPage={listingsPerPage}></DefaultPagination>}
    
        </div>
    <div className="mt-12 mb-8 flex flex-col gap-12">
      <Card>
        <CardHeader variant="gradient" color="blue" className="mb-8 p-6">
          <div className="flow-root">
            <Typography variant="h6" color="white" className="float-left">
              Dealer Information
            </Typography>
        
          </div>
        </CardHeader>
        <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
          {/* <div className="w-full min-w-[640px]">
            <Typography variant="h6" color="pink">
              You are looking at page: {page}, total count {listingsCount}
            </Typography>
          </div> */}

          <table className="w-full min-w-[640px] table-auto">
            <thead>
              <tr>
                {[
                  "Dealer Name",
                  "Individuals"
                ].map((el) => (
                  <th
                    key={el}
                    className="border-b border-blue-gray-50 py-3 px-5 text-left"
                  >
                    <Typography
                      variant="small"
                      className="text-[11px] font-bold uppercase text-blue-gray-400"
                    >
                      {el}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {listingsValue &&
                listingsValue.docs.map((doc, index) => {
                  const className = `py-3 px-5 ${
                    index === listingsValue.docs.length - 1
                      ? ""
                      : "border-b border-blue-gray-50"
                  }`;

                  return (
                    <tr key={`row${index}`}>
                      
                      <td className={className}>
                        <Typography
                          variant="small"
                          className="text-xs font-medium text-blue-gray-600"
                        >
                          {dealerName(doc)}
                        </Typography>
                      </td>

                      <td className={className}>
                        <Typography
                          variant="small"
                          className="text-xs font-medium text-blue-gray-600"
                        >
                          {dealerIndividuals(doc)}
                        </Typography>
                      </td>


                    </tr>
                  );
                })}
            </tbody>
          </table>
        </CardBody>
      </Card>
    </div>
    
    </>
  );
}

export default DealerView;
