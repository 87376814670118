// import Lister from "./Lister";
export default function GlobalairListingsAll() {
    return (
        <div>
          
            <div>
                <h1>
                    Globalair Listings
                </h1>
            </div>
            
            <div>
                TODO!
                {/* <Lister configName="globalair" listingSite="globalair_listings" default='placeholder_all' scrapesCollection="scrapes_v1" /> */}
            </div>

        </div>
    );
}
