import Lister from "./Lister";

export default function ControllerListingsAll() {

    return (
        <div>
             <div>
                <h1>
                    Controller Listings All
                </h1>
            </div>

            {/* <div>
                <Caller functionName="enqueueControllerGetListingPagesCall" text="Enqueue Controller Get Listing Pages"></Caller>
            </div> */}
            <div>
                <Lister configName="controller" listingSite='controller_listings' default='placeholder_all' scrapesCollection="scrapes_v1"/>
            </div>
        </div>
    );
}
