import DashboardCard from "../../widgets/cards/dashboard-card";
import DashboardCardPlaceholder from "../../widgets/cards/dashboard-card-placeholder";
import { useDashboardPanelData } from "../../context/DashboardContext";

export function DashboardPage() {
  const { dashPanelData } = useDashboardPanelData();
  // const { dashPanelData, loading, error } = useDashboardPanelData();
 
  const dashboardItemsLayout = () =>{

    if (!dashPanelData.length){
      return <DashboardCardPlaceholder />
    }

    const elements = [];
    for (let i = 0;i<dashPanelData.length;i++){
      const d = dashPanelData[i];
      elements.push(<DashboardCard key={`db-card${d.panelId}`} data={d}/> );
      // elements.push(<DashboardCardChart key={`db-card-chart${d.panelId}`} data={d} />);
    }

    elements.push(<DashboardCardPlaceholder key={`db-card-placeholder`} />)
    return elements
  }

  return (
    <div className="mb-12 grid gap-y-12 gap-12 gap-x-6 md:grid-cols-2 xl:grid-cols-2">
      {dashboardItemsLayout()}
    </div>
  );
}

export default DashboardPage;
