import db from "../firebase";
import React from "react"
import { collection, query, orderBy, limit } from 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';

import { getFunctions } from "firebase/functions";
import { connectFunctionsEmulator } from "firebase/functions";
import Grid from '@mui/material/Grid'; // Grid version 1
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

import './ConrollerPageStatus.css'
import {formatFirebaseTimestamp} from '../utils';
import { Accordion, AccordionBody, AccordionHeader } from "@material-tailwind/react";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


const ConrollerPageStatus = () => {

    // const functions = getFunctions(fb.app, 'us-central1');
    // const functions = getFunctions(fb.app, 'europe-west1');
    const functions = getFunctions();

    console.log('me ' + window.location.href);

    if (window.location.href.indexOf('localhost') > -1) {
        connectFunctionsEmulator(functions, "127.0.0.1", 5001)
    }

    let controller_pagecount_success = query(collection(db, 'controller_pagecount_success'), orderBy('updated','desc'), limit(5));
    let controller_pagecount_errors = query(collection(db, 'controller_pagecount_error'), orderBy('updated','desc'), limit(5));

    let planecheck_pagecount_success = query(collection(db, 'planecheck_pagecount_success'), orderBy('updated','desc'), limit(5));
    let planecheck_pagecount_errors = query(collection(db, 'planecheck_pagecount_error'), orderBy('updated','desc'), limit(5));

    let options = {
        snapshotListenOptions: { includeMetadataChanges: true },
    }

    const [c_value_success, c_loading_success, c_error_success] = useCollection(controller_pagecount_success, options);
    const [c_value_error, c_loading_error, c_error_error] = useCollection(controller_pagecount_errors, options);

    const [p_value_success, p_loading_success, p_error_success] = useCollection(planecheck_pagecount_success, options);
    const [p_value_error, p_loading_error, p_error_error] = useCollection(planecheck_pagecount_errors, options);

    const [open1, setOpen1] = React.useState(1);
    const [open2, setOpen2] = React.useState(1);
    const [open3, setOpen3] = React.useState(1);
    const [open4, setOpen4] = React.useState(1);
 
    const handleOpen1 = (value) => setOpen1(open1 === value ? 0 : value);
    const handleOpen2 = (value) => setOpen2(open2 === value ? 0 : value);
    const handleOpen3 = (value) => setOpen3(open3 === value ? 0 : value);
    const handleOpen4 = (value) => setOpen4(open4 === value ? 0 : value);

    return (
        <div>
            <Grid container spacing={0}>
                <Grid xs={3} item={true}>
                    <Item>
                        <h3>Controller Page Count Sucesses</h3>
                        {c_error_success && <strong>Error: {JSON.stringify(c_error_success)}</strong>}
                        {c_loading_success && <span>Collection: Loading...</span>}
                        {c_value_success && (
                                c_value_success.docs.map((doc, index1) => (
                                    <Accordion open={open1 === index1+1} key={"acc1_"+index1}>

                                        <AccordionHeader onClick={() => handleOpen1(index1+1)}>{formatFirebaseTimestamp(doc.data().updated)} {doc.data().searchKeyword}</AccordionHeader>
                                        <AccordionBody>
                                            {JSON.stringify(doc.data())}
                                        </AccordionBody>
                                        </Accordion>

                                ))
                        )}

                    </Item>
                </Grid>
                <Grid xs={3} item={true}>
                    <Item>
                        <h3>Controller Page Count Errors</h3>
                        {c_error_error && <strong>Error: {JSON.stringify(c_error_error)}</strong>}
                        {c_loading_error && <span>Collection: Loading...</span>}
                        {c_value_error && (
                                c_value_error.docs.map((doc, index2) => (
                                    <Accordion open={open2 === index2+1} key={"acc2_"+index2}>

                                        <AccordionHeader onClick={() => handleOpen2(index2+1)}>{formatFirebaseTimestamp(doc.data().updated)}</AccordionHeader>
                                        <AccordionBody>
                                            {JSON.stringify(doc.data())}
                                        </AccordionBody>
                                        </Accordion>

                                ))
                        )}

                    </Item>
                </Grid>
                <Grid xs={3} item={true}>
                    <Item>
                        <h3>Planecheck Page Count Sucesses</h3>
                        {p_error_success && <strong>Error: {JSON.stringify(p_error_success)}</strong>}
                        {p_loading_success && <span>Collection: Loading...</span>}
                        {p_value_success && (
                                p_value_success.docs.map((doc, index3) => (
                                    <Accordion open={open3 === index3+1} key={"acc3_"+index3}>

                                        <AccordionHeader onClick={() => handleOpen3(index3+1)}>{formatFirebaseTimestamp(doc.data().updated)} {doc.data().searchKeyword}</AccordionHeader>
                                        <AccordionBody>
                                            {JSON.stringify(doc.data())}
                                        </AccordionBody>
                                                                   </Accordion>

                                ))
                        )}

                    </Item>
                </Grid>
                <Grid xs={3} item={true}>
                    <Item>
                        <h3>Planecheck Page Count Errors</h3>
                        {p_error_error && <strong>Error: {JSON.stringify(p_error_error)}</strong>}
                        {p_loading_error && <span>Collection: Loading...</span>}
                        {p_value_error && (
                                p_value_error.docs.map((doc, index4) => (
                                    <Accordion open={open4 === index4+1}  key={"acc4_"+index4}>

                                        <AccordionHeader onClick={() => handleOpen4(index4+1)}>{formatFirebaseTimestamp(doc.data().updated)}</AccordionHeader>
                                        <AccordionBody>
                                            {JSON.stringify(doc.data())}
                                        </AccordionBody>
                                        </Accordion>

                                ))
                        )}

                    </Item>
                </Grid>
                {/* <Grid xs={4}>
                    <Item>xs=4</Item>
                </Grid>
                <Grid xs={8}>
                    <Item>xs=8</Item>
                </Grid> */}
            </Grid>



        </div>

    );
};


export default ConrollerPageStatus