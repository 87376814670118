import {
  Spinner,
    Typography,
  } from "@material-tailwind/react";
  
  import Chart from "react-apexcharts";
  import PropTypes from "prop-types";
  import React from "react";
  import { Slider, SliderThumb, styled } from "@mui/material";
  import { formatCompactNumber, formatPeriod } from "../../utils";
  import ArrowDropDownSharpIcon from '@mui/icons-material/ArrowDropDownSharp';
import { useChartData } from "../../context/DashboardContext";
  
  function ArrowThumbComponent(props) {
    const { children, ...other } = props;
    return (
      <SliderThumb {...other}>
        {children}
        <ArrowDropDownSharpIcon className="arrow-bar"></ArrowDropDownSharpIcon>
      </SliderThumb>
    );
  }
  
  const StyledSlider = styled(Slider)(({ theme }) => ({
    // color: '#3a8589',
    height: 3,
    padding: '13px 0',
    '& .MuiSlider-thumb': {
      height: 40,
      width: 40,
      boxShadow: "none",
      top: "4px",
      backgroundColor: 'transparent',
      // border: '0px solid currentColor',
      '&:hover': {
        boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
      },
      '& .arrow-bar': {
        height: "2em",
        width: "2em",
        // backgroundColor: 'currentColor',
        marginLeft: 1,
        marginRight: 1,
      },
    },
    '& .MuiSlider-thumb:before': {
      boxShadow: "none",
    },
    '& .MuiSlider-track': {
      height: 3,
    },
    '& .MuiSlider-rail': {
      color: '#d8d8d8',
      opacity: 1,
      height: 3,
  
    },
  }));
  
  
  export function MarketOverviewCard({ data }) {
   
    // const { analysisData, loading, error } = useChartData(data.panelId);
    const { analysisData} = useChartData(data.panelId);

    if (!analysisData){
      return <Spinner>Loading</Spinner>
    }


    const displayEngineTimeInDashboard = false;
  
    const priceMarks = [
      {
        value: analysisData.minPrice,
        label: formatCompactNumber(analysisData.minPrice)
      },
      {
        value: analysisData.avgPrice,
        label: formatCompactNumber(analysisData.avgPrice)
      },
      {
        value: analysisData.maxPrice,
        label: formatCompactNumber(analysisData.maxPrice)
      }
    ];
  
    const daysActiveMarks = [
      {
        value: analysisData.minDaysActive,
        label: analysisData.minDaysActive
      },
      {
        value: analysisData.avgDaysActive,
        label: analysisData.avgDaysActive
      },
      {
        value: analysisData.maxDaysActive,
        label: analysisData.maxDaysActive
      }
    ];
  
    const yearMarks = [
      {
        value: analysisData.minYears,
        label: analysisData.minYears
      },
      {
        value: analysisData.avgYears,
        label: analysisData.avgYears
      },
      {
        value: analysisData.maxYears,
        label: analysisData.maxYears
      }
    ];
  
    const totalTimeMarks = [
      {
        value: analysisData.minTT,
        label: analysisData.minTT
      },
      {
        value: analysisData.avgTT,
        label: analysisData.avgTT
      },
      {
        value: analysisData.maxTT,
        label: analysisData.maxTT
      }
    ];
  
    const engine1TimeMarks = [
      {
        value: analysisData.minE1T,
        label: analysisData.minE1T
      },
      {
        value: analysisData.avgE1T,
        label: analysisData.avgE1T
      },
      {
        value: analysisData.maxE1T,
        label: analysisData.maxE1T
      }
    ];
  
    const engine2TimeMarks = [
      {
        value: analysisData.minE2T,
        label: analysisData.minE2T
      },
      {
        value: analysisData.avgE2T,
        label: analysisData.avgE2T
      },
      {
        value: analysisData.maxE2T,
        label: analysisData.maxE2T
      }
    ];
  
    const models = (data.models && data.models.length)? data.models.map((m)=>m.label).join(", ").trim() : [];
  
  
    const chartConfigDefault = {
      type: "pie",
      width: 80,
      height: 80,
      series: [analysisData.noPriceCount, analysisData.noResults-analysisData.noPriceCount],
      options: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        title: {
          show: "",
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#d40f04", "#40a832"],
        legend: {
          show: false,
        },
      },
    };
  
    const chartConfigPrice = {...chartConfigDefault};
    chartConfigPrice["series"] = [analysisData.noPriceCount, analysisData.noResults-analysisData.noPriceCount]
    
    const chartConfigYears = {...chartConfigDefault};
    chartConfigYears["series"] = [analysisData.noYearsCount, analysisData.noResults-analysisData.noYearsCount]
    
    const chartConfigTT = {...chartConfigDefault};
    chartConfigTT["series"] = [analysisData.noTTCount, analysisData.noResults-analysisData.noTTCount]
  
    const chartConfigE1T = {...chartConfigDefault};
    chartConfigE1T["series"] = [analysisData.noE1TCount, analysisData.noResults-analysisData.noE1TCount]
    
    const chartConfigE2T = {...chartConfigDefault};
    if (analysisData.noEnginesMode === 2){
      chartConfigE2T["series"] = [analysisData.noE2TCount, analysisData.noResults-analysisData.noE2TCount]
    }
    
    const chartConfigDaysActive = {...chartConfigDefault};
    chartConfigDaysActive["series"] = [analysisData.noDaysActiveCount, analysisData.noResults-analysisData.noDaysActiveCount]
  
  
    const panels = () =>{
  
      return ( <div className="m-5">
        <Typography key={"label-manufacturer"} variant="h4" className="font-normal text-blue-gray-600">
          {data.manufacturers[0].label}
        </Typography>
        <Typography key={"label-model"} variant="h6" className="font-normal text-blue-gray-600">
          Model: {models}
        </Typography>
        <Typography key={"label-period"} variant="h6" className="font-normal text-blue-gray-600">
          Period: {formatPeriod(data.period)}
        </Typography>
        <Typography key={"matches"} variant="h6" color="blue-gray">
          [Matches: {analysisData.noResults}] [Excluded: {analysisData.noExcluded}][Duplicates: {analysisData.ignoredDups ? analysisData.ignoredDups.length : 0}]
        </Typography>
  
        <div className="grid grid-cols-10 grid-rows-1">
          <div className="col-span-9 px-2">
            <Typography>Price:</Typography>
            <StyledSlider
              slots={{ thumb: ArrowThumbComponent }}
              value={[analysisData.minPrice, analysisData.avgPrice, analysisData.maxPrice]}
              valueLabelDisplay="auto"
              min={analysisData.minPrice}
              max={analysisData.maxPrice}
              marks={priceMarks}
            />
          </div>
          <div className="col-span-1 justify-center content-center">
            <Chart {...chartConfigPrice} />
          </div>
        </div>    
  
        <div className="grid grid-cols-10 grid-rows-1">
          <div className="col-span-9 px-2">
          <Typography>Year:</Typography>
          <StyledSlider
            slots={{ thumb: ArrowThumbComponent }}
            value={[analysisData.minYears, analysisData.avgYears, analysisData.maxYears]}
            valueLabelDisplay="auto"
            min={analysisData.minYears}
            max={analysisData.maxYears}
            marks={yearMarks}
          />
          </div>
          <div className="col-span-1 justify-center content-center">
            <Chart {...chartConfigYears} />
          </div>
        </div>              
        
  
        <div className="grid grid-cols-10 grid-rows-1">
          <div className="col-span-9 px-2">
          <Typography>Days Active:</Typography>
          <StyledSlider
            slots={{ thumb: ArrowThumbComponent }}
            value={[analysisData.minDaysActive, analysisData.avgDaysActive, analysisData.maxDaysActive]}
            valueLabelDisplay="auto"
            min={analysisData.minDaysActive}
            max={analysisData.maxDaysActive}
            marks={daysActiveMarks}
          />      
        </div>
          <div className="col-span-1 justify-center content-center">
            <Chart {...chartConfigDaysActive} />
          </div>
        </div>       
  
        
  
        <div className="grid grid-cols-10 grid-rows-1">
          <div className="col-span-9 px-2">
          <Typography>Total Time:</Typography>
          <StyledSlider
            slots={{ thumb: ArrowThumbComponent }}
            value={[analysisData.minTT, analysisData.avgTT, analysisData.maxTT]}
            valueLabelDisplay="auto"
            min={analysisData.minTT}
            max={analysisData.maxTT}
            marks={totalTimeMarks}
          />            
        </div>
          <div className="col-span-1 justify-center content-center">
            <Chart {...chartConfigTT} />
          </div>
        </div>  
  
        {displayEngineTimeInDashboard &&
        <>
        <div className="grid grid-cols-10 grid-rows-1">
        <div className="col-span-9 px-2">
          <Typography>{analysisData.noEnginesMode > 1 ? "Engine 1 Time" : "Engine Time"}:</Typography>
          <StyledSlider
            slots={{ thumb: ArrowThumbComponent }}
            value={[analysisData.minE1T, analysisData.avgE1T, analysisData.maxE1T]}
            valueLabelDisplay="auto"
            min={analysisData.minE1T}
            max={analysisData.maxE1T}
            marks={engine1TimeMarks}
          />            
        </div>
        <div className="col-span-1 justify-center content-center">
          <Chart {...chartConfigE1T} />
        </div>
      </div>             
  
      {(analysisData.noEnginesMode === 2) && (
  
        <div className="grid grid-cols-10 grid-rows-1 h-full">
          <div className="col-span-9 px-2">
            <Typography>Engine 2 Time:</Typography>
            <StyledSlider
              slots={{ thumb: ArrowThumbComponent }}
              value={[analysisData.minE2T, analysisData.avgE2T, analysisData.maxE2T]}
              valueLabelDisplay="auto"
              min={analysisData.minE2T}
              max={analysisData.maxE2T}
              marks={engine2TimeMarks}
            />            
          </div>
          <div className="col-span-1 justify-center content-center">
            <Chart {...chartConfigE2T} />
            
          </div>
        </div>              
      )}
      </>
  
        }
  
        
        
   
    </div>)
    }
  
    return panels();

  }
  
  MarketOverviewCard.defaultProps = {
    color: "blue",
    footer: null,
  };
  
  MarketOverviewCard.propTypes = {
    color: PropTypes.oneOf([
      "white",
      "blue-gray",
      "gray",
      "brown",
      "deep-orange",
      "orange",
      "amber",
      "yellow",
      "lime",
      "light-green",
      "green",
      "teal",
      "cyan",
      "light-blue",
      "blue",
      "indigo",
      "deep-purple",
      "purple",
      "pink",
      "red",
    ]),
    footer: PropTypes.node,
  };
  
  MarketOverviewCard.displayName = "/src/widgets/cards/market-overview-card.jsx";
  
  export default MarketOverviewCard;
  