import {
  Tabs,
  TabsHeader,
  TabPanel,
  TabsBody,
  Tab,
  Checkbox,
} from "@material-tailwind/react";

import ManufacturersCleaning from "./ManufacturersCleaning";
import CategoriesCleaning from "./CategoriesCleaning";
import ModelCleaning from "./ModelCleaning";
import ConstraintSelector from "./ConstraintSelector";
import {useState} from "react";
import DealerCleaning from "./DealerCleaning";

export default function DataCleaningPanel() {

  const [hideFormatted, setHideFormatted] = useState(true);


  const data = [
    {
      label: "Manufacturers",
      value: "manufacturers",
      page: <ManufacturersCleaning hideFormatted={hideFormatted} />,
    },
    {
      label: "Categories",
      value: "categories",
      page: <CategoriesCleaning hideFormatted={hideFormatted} />,
    },
    {
      label: "Models",
      value: "models",
      page: <ModelCleaning hideFormatted={hideFormatted} />,
    },
    {
      label: "Dealers",
      value: "dealers",
      page: <DealerCleaning hideFormatted={hideFormatted} />,
    },
  ];

  return (
    <>

      <Tabs value="dealers">
        <TabsHeader>
          {data.map(({ label, value }) => (
            <Tab key={value} value={value}>
              {label}
            </Tab>
          ))}
        </TabsHeader>
        <TabsBody>
        
          {data.map(({ value, page }) => (
            <TabPanel key={value} value={value}>
              <div className="mt-12 mb-8 flex gap-12">
        <ConstraintSelector/>

        <Checkbox
          checked={hideFormatted}
          onChange={() => setHideFormatted(!hideFormatted)}
          label="Hide formatted"
        />

      </div>
              {page}
            </TabPanel>
          ))}
        </TabsBody>
      </Tabs>
    </>
  );
}
