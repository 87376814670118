import { useEffect, useState } from "react";
import Chart from 'react-apexcharts';

export function DistributionChart({data, title, yAxisTitle, xAxisTitle}) {
  
  const [chartOptions] = useState({
    chart: {
      type: "bar",
      height: 350,
    },
    xaxis: {
      categories: data.bins.map((bin) => bin.label),
      title: {
        text: xAxisTitle,
      },
    },
    yaxis: {
      title: {
        text: yAxisTitle,
      },
    },
    dataLabels: {
      enabled: false // Disable data labels
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    title: {
      text: title,
      align: "center",
    },
  });

  const [chartSeries, setChartSeries] = useState([
    {
      name: "Frequency",
      data: [],
    },
  ]);

  
  // Update chart data on load
  useEffect(() => {
    setChartSeries([
      {
        name: "Frequency",
        data: data.bins.map((bin) => bin.count),
      },
    ]);
  }, [data]);


  return (
    <Chart
      options={chartOptions}
      series={chartSeries}
      type="bar"
      height={350}
    />
  );
}

export default DistributionChart;
