import {
  Card,
  CardBody,
  CardHeader,
  Spinner,
  Typography,
} from "@material-tailwind/react";
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions";
import { useEffect, useState } from "react";

import { createHistogramBins, getPercentileTitle } from "../../utils";
import DistributionChart from "./DistributionChart";

const functions = getFunctions();

const func = httpsCallable(functions, "getListingScoreData");
// const funcGetListingIntegrityData = httpsCallable(functions, "getListingIntegrityData");

export function ListingAnalytics() {
  const functions = getFunctions();
  const [scoreData, setScoreData] = useState([]);
  // const [integrityData, setIntegrityData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // const [error, setError] = useState("");

  if (window.location.href.indexOf("localhost") > -1) {
    connectFunctionsEmulator(functions, "127.0.0.1", 5001);
  }

  const getListingScoreData = async () => {
    setIsLoading(true);
    // setError("");
    try {
      const results = await func();
      console.log(results.data);

      if (results.data.success) {
        setScoreData(results.data.results);
      } else {
        // setError(results.data.errorMessage);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  // const getListingIntegrityData = async () => {
  //   setIsLoading(true);
  //   // setError("");
  //   try {
  //     const results = await funcGetListingIntegrityData();
      
  //     if (results.data.success) {
  //       setIntegrityData(results.data.results);
  //       console.log(`integrity data`, results.data);
  //     } else {
  //       console.log(`could not get listing integrity data`)
  //       // setError(results.data.errorMessage);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  // const handleRefresh = () => {
  //   console.log(`handling refresh...`);
  //   getListingScoreData();
  // };

  useEffect(() => {
    getListingScoreData();
    // getListingIntegrityData();
  }, []);

  const [noBins] = useState(10);
  const [upperPercentileNoPhotos] = useState(0.9);
  const [upperPercentilePhotoSize] = useState(0.99);
  const [upperPercentileCharCount] = useState(0.9);

  const photoData = scoreData.map((s) => s.np);
  const photoDataSorted = [...photoData].sort((a, b) => a - b);
  const photoUpperPercentileIndex = Math.ceil(upperPercentileNoPhotos * photoDataSorted.length) - 1;
  const upperPhotoValue = photoDataSorted[photoUpperPercentileIndex];
  const filteredPhotoData = photoData.filter(value => value <= upperPhotoValue);
  // const noPhotosAverage =  photoData.length ?  Math.round(mean(photoData)) : "-";
  // const noPhotosMedian =  photoData.length ? median(photoData) : "-";
  const noPhotosHistogramData = createHistogramBins(photoData, noBins);
  const noPhotosUpperHistogramData = createHistogramBins(filteredPhotoData, noBins);

  const photoSizeData = scoreData.map((s) => s.aps / 1000000);
  const photoSizeDataSorted = [...photoSizeData].sort((a, b) => a - b);
  const photoSizeUpperPercentileIndex = Math.ceil(upperPercentilePhotoSize * photoSizeDataSorted.length) - 1;
  const upperPhotoSizeValue = photoSizeDataSorted[photoSizeUpperPercentileIndex];
  const filteredPhotoSizeData = photoSizeData.filter(value => value <= upperPhotoSizeValue);

  // const photoSizeAverage =  photoSizeData.length ?  Math.round(mean(photoSizeData)) : "-";
  // const photoSizeMedian =  photoSizeData.length ? median(photoSizeData) : "-";
  const photosSizeHistogramData = createHistogramBins(photoSizeData, noBins);
  const photosSizeUpperHistogramData = createHistogramBins(filteredPhotoSizeData, noBins);

  const charCountData = scoreData.map((s) => s.cc / 1000);
  const charCountDataSorted = [...charCountData].sort((a, b) => a - b);
  const charCountUpperPercentileIndex = Math.ceil(upperPercentileCharCount * charCountDataSorted.length) - 1;
  const upperCharCountSizeValue = charCountDataSorted[charCountUpperPercentileIndex];
  const filteredCharCountData = charCountData.filter(value => value <= upperCharCountSizeValue);

  // const charCountAverage =  charCountData.length ?  Math.round(mean(charCountData)) : "-";
  // const charCountMedian =  charCountData.length ? median(charCountData) : "-";
  const charCountHistogramData = createHistogramBins(charCountData, noBins);
  const charCountUpperHistogramData = createHistogramBins(filteredCharCountData, noBins);

  if (isLoading) {
    return <Spinner></Spinner>;
  }

  return (
    <div className="mt-12">

      <div className="mb-12 grid gap-y-12 gap-12 gap-x-6 md:grid-cols-3 xl:grid-cols-3">
        <Card>
          <CardHeader variant="gradient" color="blue" className="mb-8 p-6">
              <Typography variant="h6" color="white" className="float-center">Number of Listing Photos</Typography>
          </CardHeader>
          <CardBody>
            {/* <div>
              <h5>Average: {noPhotosAverage}</h5>
              <h5>Median: {noPhotosMedian}</h5>
            </div> */}
            <DistributionChart data={noPhotosHistogramData} title={""} yAxisTitle={"Count"} xAxisTitle={"No. photos"}/>
          </CardBody>
        </Card>
        <Card>
          <CardHeader variant="gradient" color="blue" className="mb-8 p-6">
              <Typography variant="h6" color="white" className="float-center">Average Photo Size (Pixels)</Typography>
          </CardHeader>
          <CardBody>
            {/* <div>
              <h5>Average: {photoSizeAverage}</h5>
              <h5>Median: {photoSizeMedian}</h5>
            </div> */}
            <DistributionChart data={photosSizeHistogramData} title={""} yAxisTitle={"Count"} xAxisTitle={"Pixels (1,000,000s)"}/>
          </CardBody>
        </Card>

        <Card>
        <CardHeader variant="gradient" color="blue" className="mb-8 p-6">
              <Typography variant="h6" color="white" className="float-center">Listing Character Count</Typography>
          </CardHeader>
          <CardBody>
            {/* <div>
              <h5>Average: {charCountAverage}</h5>
              <h5>Median: {charCountMedian}</h5>
            </div> */}
            <DistributionChart data={charCountHistogramData} yAxisTitle={"Count"} title={""} xAxisTitle={"Characters (1,000s)"}/>
          </CardBody>
        </Card>

        <Card>
        <CardHeader variant="gradient" color="blue" className="mb-8 p-6">
              <Typography variant="h6" color="white" className="float-center">Number of Listing Photos ({getPercentileTitle(upperPercentileNoPhotos)})</Typography>
          </CardHeader>
          <CardBody>
            <DistributionChart data={noPhotosUpperHistogramData} yAxisTitle={"Count"} title={""} xAxisTitle={"No. photos"}/>
          </CardBody>
        </Card>
        <Card>
        <CardHeader variant="gradient" color="blue" className="mb-8 p-6">
              <Typography variant="h6" color="white" className="float-center">Average Photo Size ({getPercentileTitle(upperPercentilePhotoSize)})</Typography>
          </CardHeader>
          <CardBody>
            <DistributionChart data={photosSizeUpperHistogramData} yAxisTitle={"Count"}  xAxisTitle={"Pixels (1,000,000s)"}/>
          </CardBody>
        </Card>

        <Card>
        <CardHeader variant="gradient" color="blue" className="mb-8 p-6">
              <Typography variant="h6" color="white" className="float-center">Listing Character Count ({getPercentileTitle(upperPercentileCharCount)})</Typography>
          </CardHeader>
          <CardBody>
            <DistributionChart data={charCountUpperHistogramData} yAxisTitle={"Count"} xAxisTitle={"Characters (1,000s)"}/>
          </CardBody>
        </Card>

      </div>
    </div>
  );
}

export default ListingAnalytics;
