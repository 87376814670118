import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Input,
  CardHeader,
  Button,
  Typography,
} from "@material-tailwind/react";

import db from "../../firebase";

import {
  collectionGroup,
  doc,
  getDocs,
  query,
  limit,
  updateDoc,
  where,
  serverTimestamp,
  getCountFromServer,
  increment
} from "firebase/firestore";

const QUERY_LIMIT = 1000;
async function getManufacturers(hideFormatted) {
  const manufacturersRef = collectionGroup(db, "manufacturer_data");
  let q = query(manufacturersRef,limit(QUERY_LIMIT));
  if (hideFormatted){
    q = query(manufacturersRef,limit(QUERY_LIMIT), where("formatted", "==", "SERVER_NEW"));
  }
  const s = await getDocs(q);
  return s;
}

async function getUnformattedManufacturerCount(){
  const ref = collectionGroup(db, "manufacturer_data");
  let q = query(ref, where("formatted", "==", "SERVER_NEW"));
  return await getCountFromServer(q);
}


export default function ManufacturersCleaning({hideFormatted}) {
  const [manufacturers, setManufacturers] = useState({});
  const [dataUpdated, setDataUpdated] = useState({});
  const [unformattedManufacturerCount, setUnformattedManufacturerCount] = useState(0);
  
  useEffect(()=>{
    getUnformattedManufacturerCount().then((snapshot)=>{
      setUnformattedManufacturerCount(snapshot.data().count);
    })
  },[dataUpdated]);

  useEffect(() => {
    
    const newMans = {};
    getManufacturers(hideFormatted).then((snapshot) => {
      for (let i = 0; i < snapshot.docs.length; i++) {
        const doc = snapshot.docs[i];
        const id = doc.id;
        const source = doc.data().source;
        // console.log(`manufacturer ${id}`);
        const key = `${id}_${source}`;
        newMans[key] = doc.data();
        newMans[key].id = id;
        newMans[key].editedName = doc.data().formattedName ? doc.data().formattedName : id;
      }
      // console.log("all manus: ", newMans);
      setManufacturers(newMans);
    });
  }, [dataUpdated, hideFormatted]);

  const handleUpdate = async (key, source, e) => {
    try {
      if (!manufacturers[key].editedName){
        console.log('nothign to update');
        return;
      }

      const docRef = doc(db, "manufacturers", source, "manufacturer_data", manufacturers[key].id);

      await updateDoc(docRef, {
        formattedName: manufacturers[key].editedName,
        formatted: increment(1),
        formattedDt: serverTimestamp()
      });
      setDataUpdated({});
      console.log("Document written with ID: ", docRef.id);
    } catch (e) {
      console.error("Error adding document: ", e);
    }

    const newData = { ...manufacturers };
    setManufacturers(newData);
  };

  const handleChange = (key, e) => {
    const newData = { ...manufacturers };
    newData[key].editedName = e.target.value;
    setManufacturers(newData);
  };

  const rows = Object.keys(manufacturers).sort().map((key, index) => {
    const className = `py-3 px-5 ${
      index === Object.keys(manufacturers).length - 1
        ? ""
        : "border-b border-blue-gray-50"
    }`;

    return (
      <tr key={key}>
        <td className={className}>
          <Typography
            className="text-xs font-medium text-blue-gray-600"
          >
            {index+1}
          </Typography>
        </td>
        <td className={className}>
          <Typography
            className="text-xs font-medium text-blue-gray-600"
          >
            {manufacturers[key].id}
          </Typography>
        </td>
        <td className={className}>
          {manufacturers[key].formattedName && <Typography
            className="text-xs font-medium text-blue-gray-600"
          >
            {manufacturers[key].formattedName}
          </Typography>
          }
        </td>
        <td className={className}>
          {manufacturers[key].formatted && <Typography
            className="text-xs font-medium text-blue-gray-600"
          >
            {manufacturers[key].formatted}
          </Typography>
          }
        </td>
        <td className={className}>
          <Typography
            className="text-xs font-medium text-blue-gray-600"
          >
            {manufacturers[key].source}
          </Typography>
        </td>
        <td className={className}>
          <Input
            className="text-xs font-medium text-blue-gray-600"
            onChange={(e) => handleChange(key, e)} defaultValue={manufacturers[key].editedName} 
          ></Input>
        </td>
        <td className={className}>
          <Button onClick={(e) => handleUpdate(key, manufacturers[key].source, e)}>Update</Button>
        </td>
      </tr>
    );
  });

  return (
    <div className="mt-12">

      <div className="mt-12 mb-8 flex flex-col gap-12">
        <Card>
          <CardHeader color="blue" className="mb-8 p-6">
            Manufacturer Name Mapping (identically named manufacturers show once) Unformatted: {unformattedManufacturerCount}
          </CardHeader>
          <CardBody>
            <table className="w-full min-w-max table-auto text-left">
              <thead>
                <tr>
                  {["Index", "Unformatted", "Formatted Name", "formatted", "Source", "Edited Name", ""].map(
                    (el) => (
                      <th
                        key={el}
                        className="border-b border-blue-gray-50 py-3 px-5 text-left"
                      >
                        <Typography
                          className="text-[11px] font-bold uppercase text-blue-gray-400"
                        >
                          {el}
                        </Typography>
                      </th>
                    )
                  )}
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </table>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
